import React, { PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import * as styles from './style.module.scss';
import { HOME_SCREEN } from '../../../router/routes';
import { Logo } from '../Logo';

type Props = {
	children: PropsWithChildren<any> | string;
	className?: string;
};

export const AuthHeaderMobile: React.FC<Props> = ({ children, className }) => {
	return (
		<div className={`${styles.container} ${className || ''}`}>
			<Link to={HOME_SCREEN}>
				<Logo />
			</Link>
			<hr />
			<div className={styles.right}>{children}</div>
		</div>
	);
};
