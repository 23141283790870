// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--LbmK5";
export var authField = "style-module--authField--PoRfb";
export var chromeframe = "style-module--chromeframe--iKhLs";
export var errorText = "style-module--errorText--L-UDW";
export var fieldControl = "style-module--fieldControl--q2eyx";
export var form = "style-module--form--6PEn2";
export var form_subtitle = "style-module--form_subtitle--xaOPs";
export var form_title = "style-module--form_title--BmfMc";
export var overlay = "style-module--overlay--6BKpl";
export var submitBlock = "style-module--submitBlock--fCmuA";
export var subtitleDefault = "style-module--subtitleDefault--dy5cr";
export var textDefault = "style-module--text-default--Ve6aw";
export var textInput = "style-module--text-input--XBkiG";
export var titleMedium = "style-module--titleMedium--25JqS";
export var videoJs = "style-module--video-js--rUJvH";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--jmFuw";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--rT7yq";
export var vjsPoster = "style-module--vjs-poster--rWknC";