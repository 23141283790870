import React from 'react';
import { Link, navigate } from 'gatsby';
import { HOME_SCREEN, LOGIN_SCREEN, RECOVER_PASS_SCREEN } from 'router/routes';
import { ResetPassForm } from 'components/molecules/ResetPassForm';
import onBoardingText from 'assets/images/onBoardingText.svg';
import { RecoverPassForm } from 'components/molecules/RecoverPassForm';
import { useAuth } from '../../../utils/hooks';
import * as styles from '../Signup/style.module.scss';
import { Logo } from '../../atoms/Logo';
import { Button } from '../../atoms/Button';
import { AuthHeaderMobile } from '../../atoms/AuthHeaderMobile';
import { WithWindowResize } from '../../../HOCS/withWindowResize';

type Props = {
	path: string;
};

export const ForgotPass: React.FC<Props> = WithWindowResize(({ isMobile, path }) => {
	const isLoggedInUser = useAuth();

	if (isLoggedInUser) {
		navigate(HOME_SCREEN);
		return null;
	}
	console.log(path, 'path');
	return (
		<div data-testid="test" className={styles.page}>
			<div className={styles.container}>
				{!isMobile && (
					<div className={styles.container_left}>
						<div className={styles.container_left_logo}>
							<Link to={HOME_SCREEN}>
								<Logo />
							</Link>
						</div>

						<div className={styles.imgBlock}>
							<img src={onBoardingText} alt="on boarding text" />
						</div>
					</div>
				)}
				<div className={styles.container_right}>
					{isMobile ? (
						<AuthHeaderMobile className={styles.mobHeader}>
							<Link to={LOGIN_SCREEN} className={styles.mobLink}>
								Login
							</Link>
						</AuthHeaderMobile>
					) : (
						<div className={styles.container_right_btnBlock}>
							<Link to={LOGIN_SCREEN}>
								<Button className="loginBtn">Login</Button>
							</Link>
						</div>
					)}
					<div className={styles.container_right_content}>
						{RECOVER_PASS_SCREEN.includes(path) ? <RecoverPassForm /> : <ResetPassForm />}
					</div>
				</div>
			</div>
		</div>
	);
});
