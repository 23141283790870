// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--D7ORl";
export var chromeframe = "style-module--chromeframe--1Gwlw";
export var container = "style-module--container--uT3nD";
export var container_left = "style-module--container_left--4QPpN";
export var container_left_logo = "style-module--container_left_logo--Sdtv-";
export var container_right = "style-module--container_right--yqI4B";
export var container_right_btnBlock = "style-module--container_right_btnBlock--iP5wP";
export var container_right_content = "style-module--container_right_content--UPr-W";
export var errorText = "style-module--errorText--iD60M";
export var imgBlock = "style-module--imgBlock--Sme3V";
export var mobHeader = "style-module--mobHeader--G48YC";
export var mobLink = "style-module--mobLink--dMWZL";
export var overlay = "style-module--overlay--nLn7y";
export var textDefault = "style-module--text-default--B3N63";
export var textInput = "style-module--text-input--K3ZYa";
export var videoJs = "style-module--video-js--Ctt1x";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--KW96u";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--RHIPg";
export var vjsPoster = "style-module--vjs-poster--ezRCn";