import React from 'react';
import { SignupForm } from 'components/molecules/SignupForm';
import { Link, navigate } from 'gatsby';
import { HOME_SCREEN, LOGIN_SCREEN } from 'router/routes';
import { Button } from 'components/atoms/Button';
import { Logo } from 'components/atoms/Logo';
import onBoardingText from 'assets/images/onBoardingText.svg';
import * as styles from './style.module.scss';
import { useAuth } from '../../../utils/hooks';
import Seo from '../../seo';
import { AuthHeaderMobile } from '../../atoms/AuthHeaderMobile';
import { WithWindowResize } from '../../../HOCS/withWindowResize';

type Props = {
	path?: string;
};

const signupTitles = {
	title: 'Create an account',
	subtitle: 'Learn from the best musicians in the world.',
};

export const Signup: React.FC<Props> = WithWindowResize(({ isMobile }) => {
	const isLoggedInUser = useAuth();

	if (isLoggedInUser) {
		navigate(HOME_SCREEN);
		return null;
	}

	return (
		<>
			<Seo title="Signup" />
			<div data-testid="test" className={styles.container}>
				<div className={styles.container_left}>
					<div className={styles.container_left_logo}>
						<Link to={HOME_SCREEN}>
							<Logo />
						</Link>
					</div>
					<div>
						<div className={styles.imgBlock}>
							<img src={onBoardingText} alt="on boarding text" />
						</div>
					</div>
				</div>
				<div className={styles.container_right}>
					{isMobile ? (
						<AuthHeaderMobile className={styles.mobHeader}>
							<Link to={LOGIN_SCREEN} className={styles.mobLink}>
								Login
							</Link>
						</AuthHeaderMobile>
					) : (
						<div className={styles.container_right_btnBlock}>
							<Link to={LOGIN_SCREEN}>
								<Button className="loginBtn">Login</Button>
							</Link>
						</div>
					)}
					<div className={styles.container_right_content}>
						<SignupForm {...signupTitles} titleVariant="titleMedium" subtitleVariant="subtitleXs" />
					</div>
				</div>
			</div>
		</>
	);
});
