import React, { useState, useCallback, useRef, useEffect } from 'react';
import Field from './field.js';
import './form.scss';
import { changePassword } from '../../../state/common/auth/thunk';
import { useDispatch } from 'react-redux';

const Form = (props) => {
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState([]);
	const [errorObj, setErrorObj] = useState('');
	const [focusedFields, setFocusedFields] = useState([]);

	const [old_password, setOldPassword] = useState('');
	const [new_password1, setNewPassword1] = useState('');
	const [new_password2, setNewPassword2] = useState('');

	const [old_passwordErr, setOldPasswordErr] = useState(
		'Your old password was entered incorrectly. Please enter it again',
	);
	const [new_password1Err, setNewPassword1Err] = useState('INCORRECT PASSWORD');
	const [new_password2Err, setNewPassword2Err] = useState('INCORRECT PASSWORD');

	const [ds, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	const dispatch = useDispatch();

	const formEl = useRef(null);

	useEffect(() => {
		props.setSuccess(success);
		if (success) {
			setOldPassword('');
			setNewPassword1('');
			setNewPassword2('');
			setSuccess(false);
		}
	}, [success]);

	useEffect(() => {
		if (errorObj !== '' && !errorObj.success) {
			showErrorsFromApiResult();
		}
	}, [errorObj]);

	useEffect(() => {
		shouldEnableButton();
	}, [old_password]);

	useEffect(() => {
		shouldEnableButton();
	}, [new_password1]);

	useEffect(() => {
		shouldEnableButton();
	}, [new_password2]);

	const handleChange = (e) => {
		if (!e) return;
		switch (e.target.id) {
			case 'old_password':
				setOldPassword(e.target.value);
				break;
			case 'new_password1':
				setNewPassword1(e.target.value);
				break;
			case 'new_password2':
				setNewPassword2(e.target.value);
				break;

			default:
				break;
		}
		shouldEnableButton();
	};

	const showErrorsFromApiResult = () => {
		const arr = errors;
		const add = (errorId) => {
			if (arr.indexOf(errorId) === -1) arr.push(errorId);
		};

		const remove = (errorId) => {
			if (arr.indexOf(errorId) > -1) arr.splice(arr.indexOf(errorId), 1);
		};

		if (errorObj.code === 206) {
			add('old_password');
		} else remove('new_password2');

		setFocusedFields(focusedFields.filter((id) => id !== arr[0]));
		setErrors(arr);
		forceUpdate();
	};

	const handleFocus = (e) => {
		setFocusedFields([...focusedFields, e.target.id]);
		setErrors(errors.filter((id) => id !== e.target.id));
		shouldEnableButton();
	};

	const handleBlur = (e) => {
		if (e.target.value === '' || e.target.value.length < 2) {
			setFocusedFields(focusedFields.filter((id) => id !== e.target.id));
			setErrors([...errors, e.target.id]);
		}
	};

	const shouldEnableButton = () => {
		var button = document.getElementById('forgotPassBtn');

		if (old_password.length > 0 && new_password1.length > 0 && new_password2.length > 0 && errors.length === 0) {
			button.disabled = false;
			button.classList.add('submitButton_enabled');
		} else {
			button.classList.remove('submitButton_enabled');
		}
	};

	const validate = () => {
		const arr = errors;

		const add = (errorId) => {
			if (arr.indexOf(errorId) === -1) arr.push(errorId);
		};

		const remove = (errorId) => {
			if (arr.indexOf(errorId) > -1) arr.splice(arr.indexOf(errorId), 1);
		};

		if (old_password === '' || old_password.length < 2) add('old_password');
		else remove('old_password');
		if (new_password1 === '' || new_password1.length < 2) add('new_password1');
		else remove('new_password1');
		if (new_password2 === '' || new_password2.length < 2) add('new_password2');
		else remove('new_password2');
		if (new_password2 !== new_password1) {
			add('new_password2');
			setNewPassword2Err("PASSWORDS DON'T MATCH");
			setFocusedFields(focusedFields.filter((id) => id !== 'new_password2'));
		} else remove('new_password2');

		return arr;
	};

	const handleSubmit = (e) => {
		const formErrors = validate();
		e.preventDefault();
		setErrors(formErrors);
		forceUpdate();

		if (formErrors.length === 0) {
			const data = {
				password_old: old_password,
				password: new_password1,
			};
			setError(false);
			dispatch(changePassword(data, setErrorObj, setSuccess));
		}
	};

	return (
		<div id="form" className="changepassFormWrapper">
			<form
				ref={formEl}
				className={`changePass-form${error ? ' changePass-form--error' : loading ? ' changePass-form--loading' : ''}`}
				onSubmit={(e) => handleSubmit(e)}
			>
				<Field
					type="password"
					id="old_password"
					label="Password"
					focused={focusedFields}
					errors={errors}
					errorText={old_passwordErr}
					value={old_password}
					onChange={(e) => handleChange(e)}
					onFocus={(e) => handleFocus(e)}
					onBlur={(e) => handleBlur(e)}
				></Field>
				<Field
					type="password"
					id="new_password1"
					label="New Password"
					focused={focusedFields}
					errors={errors}
					errorText={new_password1Err}
					value={new_password1}
					onChange={(e) => handleChange(e)}
					onFocus={(e) => handleFocus(e)}
					onBlur={(e) => handleBlur(e)}
				></Field>
				<Field
					type="password"
					id="new_password2"
					label="Repeat New Password"
					focused={focusedFields}
					errors={errors}
					errorText={new_password2Err}
					value={new_password2}
					onChange={(e) => handleChange(e)}
					onFocus={(e) => handleFocus(e)}
					onBlur={(e) => handleBlur(e)}
				></Field>

				<button id="forgotPassBtn" type="submit" disabled className="button submitButton">
					{!loading ? (
						<span>CHANGE PASSWORD</span>
					) : (
						<svg viewBox="0 0 50 50">
							<path
								fill="#fff"
								d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
							>
								<animateTransform
									attributeType="xml"
									attributeName="transform"
									type="rotate"
									from="0 25 25"
									to="360 25 25"
									dur="0.6s"
									repeatCount="indefinite"
								/>
							</path>
						</svg>
					)}
				</button>
			</form>
		</div>
	);
};

export default Form;
