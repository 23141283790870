import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import './conversationListItem.scss';
import Avatar from 'react-avatar';
import { Link, navigate } from 'gatsby';
import { BrowserView, MobileView, isBrowser, TabletView, isMobile, isTablet, isIPad13 } from 'react-device-detect';
import blackSend from '../../assets/images/icons/black_send.svg';
import avatarPlaceholder from '../../assets/images/icons/avatar_placeholder.svg';
import { setSelectedConversation } from 'state/common/conversations/action';

export default function ConversationListItem(props) {
	const { conversation, opened } = props;
	const firstMessage = conversation.first_message;
	const artist = conversation.artist;
	const dispatch = useDispatch();

	function returnCDNMediaLink(url) {
		var url = new URL(url);
		url.hostname = 'd121ftohl5zq6b.cloudfront.net';
		return url.toString();
	}

	function daysLeft() {
		var plusFiveDays = moment.unix(parseInt(conversation.created_at) + 5 * 86400);
		var today = moment();
		var result = plusFiveDays.diff(today, 'days') + 1;
		return result < 0 ? '0' : result.toString();
	}
	function openConversation() {
		dispatch(setSelectedConversation(conversation));
		navigate('/messenger');
	}

	return (
		<div
			className={opened ? 'conversationListItem' : 'conversationListItem conversationListItem_greyscale'}
			onClick={() => openConversation()}
			style={{
				height: isMobile && conversation.openedByCustomer === 0 ? '86px' : isMobile ? 'fit-content' : '86px',
			}}
		>
			<div
				className="conversationListItem_cont"
				style={{
					flexDirection: opened && isMobile ? 'column' : !opened && isMobile ? 'column' : '',
					paddingLeft: !opened && isMobile ? '0px' : '',
					paddingBottom: !opened && isMobile ? '0px' : '',
					background: !opened ? 'none' : conversation.openedByCustomer === 0 ? '#F1E1D5' : '#121212',
				}}
			>
				<div
					className={
						conversation.openedByCustomer === 0
							? 'conversationListItem_cont_left conversationListItem_cont_left_unread'
							: 'conversationListItem_cont_left'
					}
				>
					{opened && conversation.openedByCustomer === 1 && (
						<>
							<Avatar
								className="conversationListItem_cont_left_avatar"
								size="36"
								color={
									['#87D1AA', '#6277CB', '#FFC996', '#FCE2AE', '#FF7A86'][
										artist?.fullname ? artist?.fullname.length % 5 : 2
									]
								}
								name={artist?.fullname}
								maxInitials={2}
								textSizeRatio={3.7}
								round={true}
								src={artist?.picture ? returnCDNMediaLink(artist?.picture) : avatarPlaceholder}
							/>
							<p className="conversationListItem_cont_left_text">{artist?.fullname}</p>
						</>
					)}
					{opened && conversation.openedByCustomer === 0 && (
						<>
							<Avatar
								className="conversationListItem_cont_left_avatar"
								size="36"
								color={
									['#87D1AA', '#6277CB', '#FFC996', '#FCE2AE', '#FF7A86'][
										artist?.fullname ? artist?.fullname.length % 5 : 2
									]
								}
								name={artist?.fullname}
								maxInitials={2}
								textSizeRatio={3.7}
								round={true}
								src={artist?.picture ? returnCDNMediaLink(artist?.picture) : avatarPlaceholder}
							/>
							<div className="conversationListItem_cont_left_unread_notReadCont">
								<p className="conversationListItem_cont_left_unread_notReadCont_text">
									You have a new message from {artist?.name}
								</p>
								<img alt="quan" className="conversationListItem_cont_left_unread_notReadCont_img" src={blackSend} />
							</div>
						</>
					)}
					{!opened && (
						<>
							<Avatar
								className="conversationListItem_cont_right_avatar"
								size="36"
								round={true}
								src={artist?.picture ? returnCDNMediaLink(artist?.picture) : avatarPlaceholder}
							/>
							<div>
								<p className="conversationListItem_cont_left_text_archived">{artist?.fullname}</p>
								<p className="conversationListItem_cont_left_time">
									{moment.unix(firstMessage?.created_at).calendar(null, 'MM/DD/YYYY')}
								</p>
							</div>
							{/* {isMobile && (
                  <img alt='quan'
                    src={nextBtn}
                    className="conversationListItem_cont_left_nextBtn"></img>
                )} */}
						</>
					)}
				</div>
				{conversation.openedByCustomer === 1 && (
					<div
						className="conversationListItem_cont_right"
						style={{
							marginLeft: opened && isMobile ? '50px' : !opened && isMobile ? '0px' : '',
							marginBottom: opened && isMobile ? '0px' : !opened && isMobile ? '10px' : '',
						}}
					>
						{opened && (
							<>
								<p className="conversationListItem_cont_right_waiting">
									{/* TODO : state for answered question*/}
									{conversation.answered === 1 && conversation.rating == null && conversation.closed === 1
										? 'Waiting for your review'
										: conversation.declined == null
										? 'Waiting for acceptance'
										: 'Waiting for answer'}
								</p>
								{conversation.answered !== 1 && (
									<p className="conversationListItem_cont_right_daysLeft">
										{daysLeft()}
										{daysLeft() === 1 ? ' Day left' : ' Days left'}
									</p>
								)}
							</>
						)}
						{!opened && (
							<>
								<p className="conversationListItem_cont_right_daysLeft conversationListItem_cont_right_daysLeft_closed">
									{firstMessage?.message}
								</p>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

ConversationListItem.PropTypes = {
	key: PropTypes.string,
	conversationId: PropTypes.number,
	opened: PropTypes.bool,
};
