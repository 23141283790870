import React from 'react';
import { Router } from '@reach/router';
import Layout from 'components/layout';
import { Login } from 'components/organisms/Login';
import { Signup } from 'components/organisms/Signup';
import { ForgotPass } from 'components/organisms/ForgotPass';
import { CheckYourEmail } from 'components/organisms/CheckYourEmail';
import Artist from './artist';
import { Page404 } from '../components/organisms/Page404';
import DashboardPage from '../privatePages/dashboard';
import ProfilePage from '../privatePages/profile';
import MessengerPage from '../privatePages/messenger';
import PrivateRoute from '../router/PrivateRoute';

const App = () => {
	return (
		<Layout>
			<Router basepath="/">
				<Login path="login" />
				<Signup path="signup" />
				<ForgotPass path="forgot" />
				<ForgotPass path="changePassword" />
				<CheckYourEmail path="check_email" />
				<Artist path=":name" />
				<PrivateRoute path="profile" component={ProfilePage} />
				<PrivateRoute path="dashboard" component={DashboardPage} />
				<PrivateRoute path="messenger" component={MessengerPage} />

				<Page404 path="*" />
			</Router>
		</Layout>
	);
};

export default App;
