import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './conversationDeclinedComponent.scss';
import { Link } from 'gatsby';
import { TweenMax as TM, ScrollToPlugin, Power2, Power3 } from 'gsap/all';
import gsap from 'gsap';
import { DASHBOARD } from 'router/routes';
gsap.registerPlugin(ScrollToPlugin);

class ConversationDeclinedComponent extends React.Component {
	constructor(props) {
		super(props);
		this.user = this.props.user;
		this.state = {};
	}

	componentDidUpdate() {}

	componentDidMount() {
		let scrollHeight = document.body.scrollHeight;
		gsap.to(window, 0.5, { scrollTo: { y: scrollHeight }, delay: 1.3 });
	}

	render() {
		return (
			<div className="conversationDeclined" ref={(ref) => (this.screen = ref)}>
				<div className="conversationDeclined_cont">
					<h2>Your reqest has been declined</h2>
					<div className="conversationDeclined_cont_textBlock">
						{this.props.declineReason === 'Inappropriate question' && (
							<p className="conversationDeclined_cont_textBlock_label">
								Unfortunately, the artist marked your question as inappropriate. Please check back at a later date. Your
								payment will be refunded to the original method of payment in 3-5 business days.
							</p>
						)}
						{this.props.declineReason === 'I’m unable to answer' && (
							<p className="conversationDeclined_cont_textBlock_label">
								Unfortunately, the artist is unable to answer at this time. Please check back at a later date. Your
								payment will be refunded to the original method of payment in 3-5 business days.
							</p>
						)}
						{this.props.declineReason !== 'Inappropriate question' &&
							this.props.declineReason !== 'I’m unable to answer' && (
								<>
									<div className="conversationDeclined_cont_textBlock_otherReasonBlock">
										<p>{this.props.declineReason}</p>
									</div>
									<p className="conversationDeclined_cont_textBlock_label">
										Please check back at a later date. Your payment will be refunded to the original method of payment
										in 3-5 business days.
									</p>
								</>
							)}
					</div>

					<Link to={DASHBOARD}>
						<div className="link conversationDeclined_cont_backLink">
							<p>Continue Browsing</p>
						</div>
					</Link>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
	};
};

// const mapDispatchToProps = (dispatch) =>
// 	bindActionCreators(
// 		{
// 			fetchAllConversations,
// 		},
// 		dispatch,
// 	);

ConversationDeclinedComponent.propTypes = {
	user: PropTypes.object,
	declineReason: PropTypes.string,
};

export default connect(mapStateToProps, null)(ConversationDeclinedComponent);
