import React, { useState } from 'react';
import moment from 'moment';
import './Message.scss';
import VideoPlayer from '../../components/molecules/VideoPlayer/VideoPlayer.js';
import downloadFile from '../assets/images/icons/download_file.svg';
import downloadFileHover from '../assets/images/icons/download_file_hover.svg';

const formats = {
	sameDay: '[Today]',
	nextDay: '[Tomorrow]',
	nextWeek: 'dddd',
	lastDay: '[Yesterday]',
	lastWeek: '[Last] dddd',
	sameElse: 'MM/DD/YYYY',
};

export default function Message(props) {
	const { data, isMine, startsSequence, endsSequence, showTimestamp } = props;
	const [downloadButotnEnter, setDownloadButotnEnter] = useState(false);

	const friendlyTimestamp = moment.unix(data.created_at).calendar(null, formats);

	return (
		<div
			className={[
				'message',
				`${isMine ? 'mine' : ''}`,
				`${startsSequence ? 'start' : ''}`,
				`${endsSequence ? 'end' : ''}`,
			].join(' ')}
		>
			{showTimestamp && <div className="timestamp">{friendlyTimestamp}</div>}

			<div className="bubble-container">
				<div className="bubble-container_inner">
					{data.message && (
						<div className="bubble" title={friendlyTimestamp}>
							{data.message}
						</div>
					)}
					{data.file && (
						<div className="bubble-container_inner_videoCont">
							<VideoPlayer
								videoUrl={data.file}
								videoIsProcessed={data.isProcessed}
								showSkip={false}
								controlsAtPreview={false}
								messengerIntroVideo={false}
							/>
							{data.isShareable === 1 && (
								// <a href={data.file} download="video.mp4" target="_blank">
								<form method="get" action={data.file}>
									<button type="submit">
										<img
											alt="quan"
											className="bubble-container_inner_videoCont_img"
											onMouseEnter={() => setDownloadButotnEnter(true)}
											onMouseLeave={() => setDownloadButotnEnter(false)}
											src={downloadButotnEnter ? downloadFileHover : downloadFile}
										/>
									</button>
								</form>
								// </a>
							)}
						</div>
					)}
					{endsSequence && (
						<span className={`endTime${isMine ? ' endTime_mine' : ''}`}>
							{moment.unix(data.created_at).format('hh:mm A')}
						</span>
					)}
				</div>
			</div>
		</div>
	);
}
