// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--Saer+";
export var chromeframe = "style-module--chromeframe--+9jGt";
export var container = "style-module--container--IkgLt";
export var errorText = "style-module--errorText--Pw4GM";
export var overlay = "style-module--overlay--2+5Ou";
export var textDefault = "style-module--text-default--9Kfaw";
export var textInput = "style-module--text-input--0iEyd";
export var videoJs = "style-module--video-js--eIhbg";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--Ab-3o";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--HhLi9";
export var vjsPoster = "style-module--vjs-poster--r5n2f";