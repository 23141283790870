import React, { useState, useEffect } from 'react';
import eyeOnImg from '../../assets/images/icons/eye.svg';
import eyeOffImg from '../../assets/images/icons/eye-off.svg';

const Input = ({ type, id, required, value, onChange, onFocus, onBlur }) => {
	if (type === 'text')
		return <input id={id} type="text" name={id} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />;
	if (type === 'textarea')
		return (
			<textarea
				id={id}
				type="text"
				name={id}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
			></textarea>
		);
	if (type === 'password')
		return (
			<input id={id} type="password" name={id} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />
		);
};

const Field = ({ type, id, label, value, focused, errors, errorText, onChange, onFocus, onBlur }) => {
	const [eyeClicked, setEyeClicked] = useState(true);

	const eye = () => {
		var d = document.getElementById(id);
		if (d.type === 'password') {
			d.type = 'text';
			setEyeClicked(true);
		} else {
			d.type = 'password';
			setEyeClicked(false);
		}
	};

	return (
		<div
			className={`changePass-form__field${
				focused.indexOf(id) > -1
					? ' changePass-form__field--focus'
					: errors.indexOf(id) > -1 && value === ''
					? ' changePass-form__field--error'
					: errors.indexOf(id) > -1 && value !== ''
					? ' changePass-form__field--error changePass-form__field--error-notEmpty'
					: ''
			}`}
		>
			<label htmlFor={id} className="changePass-form__label">
				<span>{label}</span>
				<div>{errorText}</div>
			</label>

			<Input type={type} id={id} value={value} onChange={onChange} onFocus={onFocus} onBlur={onBlur} />
			{id === 'old_password' ? (
				<img
					alt="quan"
					onClick={eye}
					id="password_eye"
					className="changePass-form_pass_eye"
					src={eyeClicked === true ? eyeOnImg : eyeOffImg}
				/>
			) : (
				''
			)}
		</div>
	);
};

export default Field;
