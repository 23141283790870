import React, { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import PropTypes from "prop-types";
import animationData from "./loader_justLine.json";
import "./checkout.scss";

const CheckoutButton = (props) => {
  const [disabled, setDisabled] = useState(props.disabled);
  const [loader, setLoader] = useState(false);
  const loaderBox = useRef(null);
  let animBox = null;

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    if (animBox == null && loaderBox.current !== null) {
      animBox = lottie.loadAnimation({
        container: loaderBox.current, // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData // the path to the animation json
      });
    }
  }, []);

  const redirectToCheckout = async (event) => {
    setLoader(true);
    event.preventDefault();
    setDisabled(true);
    props.sendAndPay();
  };
  return (
    <button
      disabled={disabled}
      className={props.class + " " + (disabled || loader ? "button_disabled" : "")}
      onClick={redirectToCheckout}>
      {loader === false && <p>{props.label}</p>}
      <div
        style={{
          display: loader === true ? "flex" : "none"
        }}
        ref={loaderBox}
        className="loader_lottie"></div>
    </button>
  );
};

CheckoutButton.propTypes = {
  price: PropTypes.string,
  product_name: PropTypes.string,
  sendAndPay: PropTypes.func
};

export default CheckoutButton;
