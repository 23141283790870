import { AppDispatch } from 'state/createStore';
import axios from 'axios';
import {
	GET_ALL_CONVERSATIONS,
	GET_CONVERSATION,
	GET_ARTIST_FOR_CONVERSATION,
	GET_FIRST_MESSAGE_OF_CONVERSATION,
	GET_MESSAGES,
	GET_MESSAGES_OF_ARTIST_AND_CUSTOMER,
	SET_SEEN_BY_CUSTOMER,
	CREATE_CONVERSATION,
	SEND_REVIEW,
	HANDLE_CONVERSATION_PAYMENT,
} from 'ServerAPI/api';

import { Conversation, FirstMessageOfConversation } from 'types/conversation';

import {
	getAllConversations,
	getConversation,
	getArtistForConversation,
	getFirstMessageOfConversation,
	getMessages,
	getMessagesOfCustomerAndArtist,
	getSeenConversationByCustomer,
	createConversation,
	createReview,
	handlePayment,
} from './action';

export const fetchAllConversations = (data?: Conversation) => async (dispatch: AppDispatch) => {
	if (data) {
		dispatch(getAllConversations(data));
		return;
	}
	try {
		const {
			data: { data },
		} = await axios.get(`${GET_ALL_CONVERSATIONS}`);
		dispatch(getAllConversations(data));
	} catch (error) {
		console.log(error);
	}
};

export const fetchConversation = (id: string | number, data?: Conversation) => async (dispatch: AppDispatch) => {
	if (data) {
		dispatch(getConversation(data));
		return;
	}
	try {
		const url = `${GET_CONVERSATION}?conversationId=${id}`;
		const {
			data: { data },
		} = await axios.get(url);
		dispatch(getConversation(data));
	} catch (error) {
		console.log(error);
	}
};

export const fetchFirstMessageOfConversation = (id: string | number) => async (dispatch: AppDispatch) => {
	try {
		const url = `${GET_FIRST_MESSAGE_OF_CONVERSATION}?conversationId=${id}`;
		const {
			data: { data },
		} = await axios.get(url);
		dispatch(getFirstMessageOfConversation(data));
	} catch (error) {
		console.log(error);
	}
};

export const fetchArtistForConversation = (id: number) => async (dispatch: AppDispatch) => {
	try {
		const url = `${GET_ARTIST_FOR_CONVERSATION}?id=${id}`;
		const {
			data: { data },
		} = await axios.get(url);
		dispatch(getArtistForConversation(data));
	} catch (error) {
		console.error(error);
	}
};

export const fetchMessages = (id: number) => async (dispatch: AppDispatch) => {
	try {
		const url = `${GET_MESSAGES}?conversationId=${id}`;
		const {
			data: { data },
		} = await axios.get(url);
		dispatch(getMessages(data));
	} catch (error) {
		console.error(error);
	}
};

export const fetchMessagesOfCustomerAndArtist = (id: number) => async (dispatch: AppDispatch) => {
	try {
		const url = `${GET_MESSAGES_OF_ARTIST_AND_CUSTOMER}?artistId=${id}`;
		const {
			data: { data },
		} = await axios.get(url);
		dispatch(getMessagesOfCustomerAndArtist(data));
	} catch (error) {
		console.error(error);
	}
};

export const setSeenConversation = (id: number) => async (dispatch: AppDispatch) => {
	try {
		const url = `${SET_SEEN_BY_CUSTOMER}?conversationId=${id}`;
		const {
			data: { data },
		} = await axios.post(url);
		dispatch(getSeenConversationByCustomer(data));
	} catch (error) {
		console.error(error);
	}
};

export const createNewConversation = (inputData: any) => async (dispatch: AppDispatch) => {
	try {
		const url = `${CREATE_CONVERSATION}`;
		const {
			data: { data },
		} = await axios.post(url, inputData);
		dispatch(createConversation(data));
	} catch (error) {
		console.error(error);
	}
};

export const sendReview = (inputData: any) => async (dispatch: AppDispatch) => {
	try {
		const url = `${SEND_REVIEW}`;
		const {
			data: { data },
		} = await axios.post(url, inputData, { headers: { 'Content-Type': 'multipart/form-data' } });
		dispatch(createReview(data));
	} catch (error) {
		console.error(error);
	}
};

export const handleConversationPayment = (inputData: any, callback: Function) => {
	return async (dispatch: AppDispatch) => {
		try {
			const url = `${HANDLE_CONVERSATION_PAYMENT}`;
			await axios.post(url, { ...inputData }).then((result) => {
				dispatch(handlePayment(result));
				callback(result);
			});
		} catch (error) {
			console.error(error);
		}
	};
};
