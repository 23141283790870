import React from "react";
import Form from "./form";
import "./changePassModal.scss";
import { TweenMax as TM, Power2 } from "gsap/all";
import { TweenMax, Power3, Power1, Power0 } from "gsap/all";

class changePassModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.show = this.props.showForm;
    this.state = {
      success: false
    };
    this.showchangePassModal = this.showchangePassModal.bind(this);
    this.closechangePassModal = this.closechangePassModal.bind(this);
  }

  setSuccess = val => {
    if (val) {
      TweenMax.to("#changePassModalBody", 0.5, {
        opacity: 0,
        ease: Power1.easeIn,
        onComplete: function () {
          TweenMax.to("#changePassModalBody", 0.1, {
            scale: 0,
            ease: Power3.easeOut,
            onComplete: function () {
              document.querySelectorAll(
                "#changePassModalBody"
              )[0].style.display = "none";
              document.querySelectorAll("#successOptionMsg")[0].style.display =
                "flex";
              TweenMax.to("#successOptionMsg", 0.05, {
                scale: 1,
                ease: Power3.easeIn,
                onComplete: function () {
                  TweenMax.to("#successOptionMsg", 0.7, {
                    opacity: 1,
                    ease: Power3.easeOut
                  });
                }.bind(this)
              });
            }.bind(this)
          });
        }.bind(this)
      });
    }
  };

  animateText = () => {
    TM.fromTo(
      this.text1,
      1,
      { opacity: 0, transform: "translateY(20%)" },
      {
        opacity: 1,
        delay: 0.2,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    );
    TM.fromTo(
      this.form,
      1,
      { opacity: 0, transform: "translateY(20%)" },
      {
        opacity: 1,
        delay: 0.3,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    );
    TM.fromTo(
      this.cancel,
      1,
      { opacity: 0, transform: "translateY(20%)" },
      {
        opacity: 1,
        delay: 0.4,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    );
  };

  showchangePassModal() {
    this.forceUpdate();
    document.querySelectorAll("#changePassModal")[0].style.display = "flex";
    document.querySelectorAll("#changePassModalBody")[0].style.display = "flex";
    document.querySelectorAll("#changePassModalBody")[0].style.opacity = 0;
    document.querySelectorAll("#changePassModalBody")[0].style.transform =
      "none";

    TweenMax.to("#changePassModal", 0.05, {
      scale: 1,
      ease: Power0.easeNone,
      onComplete: function () {
        TweenMax.to("#changePassModal", 0.2, {
          opacity: 1,
          ease: Power0.easeNone,
          delay: 0.2,
          onComplete: function () {
            document.querySelector("html").classList.add("stop-scrolling");
            document.querySelectorAll(
              "#changePassModalBody"
            )[0].style.visibility = "visible";
            TweenMax.to(
              document.querySelectorAll("#changePassModalBody")[0],
              0.25,
              {
                opacity: 1,
                ease: Power0.easeNone,
                onCompelete: () => this.animateText()
              }
            );
          }.bind(this)
        });
      }.bind(this)
    });
  }

  closechangePassModal() {
    document.querySelector("html").classList.remove("stop-scrolling");
    TweenMax.to("#changePassModal", 0.5, {
      opacity: 0,
      ease: Power1.easeIn,
      onComplete: function () {
        TweenMax.to("#changePassModal", 0.1, {
          scale: 0,
          ease: Power3.easeOut,
          onComplete: function () {
            this.props.callBackForFormShow("none");
            document.querySelectorAll("#changePassModal")[0].style.display = "none";
            document.querySelectorAll("#successOptionMsg")[0].style.display =
              "none";
            this.setState({
              success: false
            });
          }.bind(this)
        });
      }.bind(this)
    });
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.showForm !== this.props.showForm) {
      if (nextProps.showForm !== "none") {
        this.showchangePassModal();
      }
    }
  }

  render() {
    return (
      <div
        class="changePassModal"
        id="changePassModal"
        style={{ display: `${this.props.showForm}` }}>
        <div id="changePassModalBody" className="changePassModal-body">
          <p className="t1" ref={ref => (this.text1 = ref)}>Change your password</p>
          <div ref={ref => (this.form = ref)}>
            <Form setSuccess={this.setSuccess}></Form>
          </div>
          <p ref={ref => (this.cancel = ref)} className="t3 changePassModal-body_close" onClick={this.closechangePassModal}>
            Cancel
          </p>
        </div>

        <div id="successOptionMsg" class="successMsg">
          <p className="t1 successMsg_title">Password changed!</p>
          <p className="t2 successMsg_subtitle">Your password has been changed successfully.</p>
          <div onClick={this.closechangePassModal} className="button successMsg_btn">DONE</div>
        </div>
      </div>
    );
  }
}

export default changePassModalComponent;
