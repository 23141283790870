import { AppDispatch } from 'state/createStore';
import axios from 'axios';
import { GET_COACHMARKS, UPDATE_COACHMARK } from 'ServerAPI/api';
import { Coachmark } from 'types/coachmark';
import { getCoachmarks, updateCoachmark } from './action';

export const fetchCoachmarks = (data?: Coachmark) => async (dispatch: AppDispatch) => {
	if (data) {
		dispatch(getCoachmarks(data));
		return;
	}
	try {
		const {
			data: { data },
		} = await axios.get(`${GET_COACHMARKS}`);
		dispatch(getCoachmarks(data));
	} catch (error) {
		console.log(error);
	}
};

export const changeCoachmark = (field: string | number, data?: Coachmark) => async (dispatch: AppDispatch) => {
	if (data) {
		dispatch(updateCoachmark(data));
		return;
	}
	try {
		const { data } = await axios.post(`${UPDATE_COACHMARK}`, { field });
		dispatch(updateCoachmark(field));
	} catch (error) {
		console.log(error);
	}
};
