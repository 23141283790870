export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  var mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  if(mimeString === ""){
    mimeString = "video/quicktime";
  }
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const ProcessVideo = (video) => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(video);
    const blob = await res.blob();
    var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function (e) {
      resolve(e);
    };
  });
};
