import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './coachmarkComponent.scss';
import { TweenMax as TM, Bounce } from 'gsap/all';
import lottie from 'lottie-web';
import animationData1 from './pulse_oval.json';
import arrow from '../../assets/images/icons/arrow_down.svg';
import { fetchCoachmarks, changeCoachmark } from 'state/common/coachmarks/thunk';

let animObj1 = null;

class CoachmarkComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			text: '',
			showMessage: false,
			position: 'up',
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.text !== this.props.text) {
			this.setState({ text: this.props.text });
		}
		if (nextProps.position !== this.props.position) {
			this.setState({ position: this.props.position });
		}
		if (nextProps.field !== this.props.field) {
			this.checkIfShouldShow();
		}
		if (nextProps.showMessage !== this.props.showMessage) {
			this.setState({ showMessage: this.props.showMessage });
			if (nextProps.showMessage) {
				this.checkIfShouldShow();
			} else {
				this.hideMessage();
			}
		}
		if (nextProps.coachmarks !== this.props.coachmarks) {
			this.checkIfShouldShow(nextProps.coachmarks);
		}
	}

	checkIfShouldShow = (coachmarks) => {
		if (coachmarks) {
			const alreadyShown = parseInt(coachmarks[this.getField()]) === 1;
			this.setState({
				coachmarkAlreadyShown: alreadyShown,
			});
			if (!alreadyShown) {
				this.showMessage();
			}
		}
	};

	componentDidMount() {
		this.props.fetchCoachmarks();
		this.setState({
			text: this.props.text,
			showMessage: this.props.showMessage,
			position: this.props.position,
		});
		animObj1 = lottie.loadAnimation({
			container: this.animBox1, // the dom element that will contain the animation
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: animationData1, // the path to the animation json
		});
	}

	getField = () => {
		switch (parseInt(this.props.field)) {
			case 1:
				return 'dashboard_ask_artist';
				break;
			case 2:
				return 'dashboard_new_message';
				break;
			case 3:
				return 'messanger_select_conversation';
				break;
			case 4:
				return 'messenger_text_input';
				break;
			case 5:
				return 'messanger_file_input';
				break;
			default:
				break;
		}
	};

	hideMessage = (e) => {
		e.preventDefault();
		e.stopPropagation();
		var field = this.getField();
		this.props.changeCoachmark(field);
		TM.to(this.closeButton, 0.1, {
			scale: 1.1,
			onComplete: function () {
				TM.to(this.closeButton, 0.1, { scale: 1, delay: 0 });
			}.bind(this),
		});
		TM.to(this.coachmark, 0.4, {
			opacity: 0,
			scale: 1,
			delay: 0.3,
			onComplete: function () {
				this.coachmark.style.display = 'none';
			}.bind(this),
		});
		if (this.props.buttonClickCallback) {
			this.props.buttonClickCallback();
		}
	};

	showMessage = () => {
		this.coachmark.style.display = 'flex';
		TM.fromTo(
			this.coachmark,
			0.4,
			{ opacity: 0, scale: 1 },
			{
				opacity: 1,
				scale: 1.1,
				delay: 0.2,
				ease: Bounce.easeOut,
			},
		);
		TM.to(this.coachmark, 0.2, { opacity: 1, scale: 1, delay: 0.4 });
	};

	render() {
		return (
			<div ref={(ref) => (this.coachmark = ref)} className="coachmarkComponent">
				<div className="coachmarkComponent__bubbleCont">
					<p className="coachmarkComponent__bubbleCont_text">{this.state.text}</p>
					<div onClick={(e) => this.hideMessage(e)} className="coachmarkComponent__bubbleCont_btn">
						<p>{this.props.buttonText ? this.props.buttonText : 'I GOT IT'}</p>
					</div>
				</div>
				<div className={`coachmarkComponent__arrow coachmarkComponent__arrow_${this.state.position}`}>
					<img alt="quan" className="coachmarkComponent__arrow_arrow" src={arrow} />
					<div className="coachmarkComponent__arrow_lottie" ref={(ref) => (this.animBox1 = ref)} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		coachmarks: state.coachmarks,
	};
};

CoachmarkComponent.propTypes = {
	user: PropTypes.object,
	coachmarks: PropTypes.object,
};

export default connect(mapStateToProps, { fetchCoachmarks, changeCoachmark })(CoachmarkComponent);
