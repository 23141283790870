import { LoginFormType, RecoverPassFormType, ResetPassFormType, SignupFormType } from 'types/auth';

export const signupInitialState = {
	fullname: {
		name: 'fullname',
		value: '',
	},
	email: {
		name: 'email',
		value: '',
	},
	password: {
		name: 'password',
		value: '',
	},
};

export const loginInitialState = {
	email: {
		name: 'email',
		value: '',
	},
	password: {
		name: 'password',
		value: '',
	},
};

export const resetPassInitialSate = {
	email: {
		name: 'email',
		value: '',
	},
};

export const recoverPassInitialSate = {
	password: {
		name: 'password',
		value: '',
	},
	confirm: {
		name: 'confirm',
		value: '',
	},
};

export const signupFormTemplate: SignupFormType = {
	fullname: {
		name: 'fullname',
		type: 'text',
		value: '',
		placeholder: 'Full Name',
	},
	email: {
		name: 'email',
		type: 'email',
		value: '',
		placeholder: 'E-mail',
	},
	password: {
		name: 'password',
		type: 'password',
		value: '',
		placeholder: 'Password',
	},
};

export const loginFormTemplate: LoginFormType = {
	email: {
		name: 'email',
		type: 'text',
		value: '',
		placeholder: 'E-mail',
	},
	password: {
		name: 'password',
		type: 'password',
		value: '',
		placeholder: 'Password',
	},
};

export const resetPassFormTemplate: ResetPassFormType = {
	email: {
		name: 'email',
		type: 'text',
		value: '',
		placeholder: 'E-mail',
	},
};

export const recoverPassFormTemplate: RecoverPassFormType = {
	password: {
		name: 'password',
		type: 'password',
		value: '',
		placeholder: 'Password',
	},
	confirm: {
		name: 'confirm',
		type: 'password',
		value: '',
		placeholder: 'Confirm Password',
	},
};
