import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import { Button } from 'components/atoms/Button';
import { loginFormTemplate } from 'config/auth';
import { useDispatch } from 'react-redux';
import { AuthFormFieldType } from 'types/auth';
import { userLogin, verifyEmail } from 'state/common/auth/thunk';
import { StringParam, useQueryParam } from 'use-query-params';
import * as styles from './style.module.scss';
import { authValidation, initValidation, ValidResponse } from '../../../utils/validation';
import { FORGOT_PASS_SCREEN } from '../../../router/routes';
import { AuthField } from '../../atoms/AuthField';

const initForm = {
	email: '',
	password: '',
};

export const LoginForm: React.FC = () => {
	const [form, setForm] = useState(initForm);
	const [{ errorField }, setValidation] = useState<ValidResponse>(initValidation);
	const dispatch = useDispatch();

	const [email] = useQueryParam('email', StringParam);
	const [token] = useQueryParam('token', StringParam);

	useEffect(() => {
		if (email && token) {
			dispatch(verifyEmail({ email, token }));
			// 	activateAccount({ email, token })
			// 		.then(() => {
			// 			// navigate(HOME_SCREEN);
			// 		})
			// 		.catch((e) => {
			// 			console.log(e.message, 'login page');
			// 		});
			//
		}
	}, [email, token, dispatch]);

	const onFieldChange = (name: string, value: string) => {
		if (name === 'email' || name === 'password') {
			setForm((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();

		const { email, password } = form;
		const validation = authValidation(email, password);
		// temp-remove
		if (validation.success || true) {
			dispatch(
				userLogin({
					email,
					password,
				}),
			);
			setValidation(initValidation);
		} else {
			setValidation(validation);
		}
	};

	const fields = useMemo(
		() =>
			Object.values(loginFormTemplate).map((fieldProps: AuthFormFieldType, index) => {
				return (
					<AuthField
						className={styles.authField}
						{...fieldProps}
						onChange={onFieldChange}
						key={`log_${fieldProps.name || index}`}
						error={errorField[fieldProps.name as keyof typeof errorField]}
						value={form[fieldProps.name as keyof typeof form]}
					/>
				);
			}),
		[form, errorField],
	);

	return (
		<form className={styles.form} data-testid="test" onSubmit={handleSubmit}>
			<h1 className={styles.form_title}>Login</h1>
			<p className={styles.form_subtitle}>Already a member? Login!</p>
			<div className={styles.fieldControl}>{fields}</div>
			<div className={styles.forgotPass}>
				<Link to={FORGOT_PASS_SCREEN} className={`text-default ${styles.forgotLink}`}>
					Forgot password?
				</Link>
			</div>
			<div className={styles.submitBlock}>
				<Button className="loginFormBtn" type="submit">
					LOG IN
				</Button>
			</div>
		</form>
	);
};
