import React, { useRef, useEffect, useState } from 'react';
import { IonBadge, IonItem, IonLabel, IonContent } from '@ionic/react';
import { sendReview } from '../utils/api.js';
import logo from '../assets/images/logo/grey_text_logo.svg';
import star from '../assets/images/icons/star.svg';
import star_grey from '../assets/images/icons/star_grey.svg';
import lottie from 'lottie-web';
import starsAnimation from '../../assets/lotties/loaders/stars.json';
import VisibilitySensor from 'react-visibility-sensor';
import autosize from 'autosize';
import './messengerReviewComponent.scss';
import { addReviewToConversation } from 'state/common/conversations/action';
import { useDispatch } from 'react-redux';

const ReviewStrings = ['Bad', 'Fair', 'Good', 'Great', 'Awesome'];
const ReviewReasons = ['Video quality', 'Response time', 'Communication', 'Value for money', 'Overall experience'];

const MessengerReviewComponent = (props) => {
	var animObj = null;
	var animBox1 = useRef(null);
	const [animationPlayed, setAnimationPlayed] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [stars, setStars] = useState(4);
	const [comment, setComment] = useState('');
	const [ratingReasons, setRatingReasons] = useState([]);
	const [enabledBtn, setEnabledBtn] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (animObj) {
			animObj.destroy();
		}
		if (props.reviewObj) {
			setRatingReasons(props.reviewObj.rating_reasons ? [...props.reviewObj.rating_reasons] : []);
			setComment(props.reviewObj.rating_reasons ? props.reviewObj.comment : '');
			setStars(props.reviewObj.rating_stars);
		} else {
			setIsEditable(true);
		}
		setTimeout(() => {
			autosize(document.querySelectorAll('textarea'));
		}, 50);
		return () => {
			animObj?.destroy();
		};
	}, []);

	useEffect(() => {
		if (stars && ratingReasons.length > 0) {
			setEnabledBtn(true);
		} else {
			setEnabledBtn(false);
		}
	}, [stars, ratingReasons]);

	function handleReviewSend() {
		var data = {
			conversationId: props.conversationId,
			review: stars,
			description: comment !== '' ? comment : null,
			reasons: ratingReasons.length > 0 ? ratingReasons : null,
		};
		dispatch(addReviewToConversation({ comment, rating_reasons: ratingReasons, rating_stars: stars }));
		sendReview(data)
			.then((result) => {
				setIsEditable(false);
				dispatch(addReviewToConversation({ comment, rating_reasons: ratingReasons, rating_stars: stars }));
			})
			.catch(() => {
				console.log('failed to send Review');
			});
	}

	function componentVisibilitySensor(isVisible) {
		console.log('visibility', isVisible);
		if (isVisible) {
			setTimeout(() => {
				if (animObj) {
					animObj?.destroy();
				}
				if (animationPlayed === false && isEditable === false) {
					animObj = lottie.loadAnimation({
						container: animBox1.current, // the dom element that will contain the animation
						renderer: 'svg',
						loop: false,
						autoplay: true,
						animationData: starsAnimation, // the path to the animation json
					});
					setAnimationPlayed(true);
				}
			}, 1500);
		}
	}

	function getStars() {
		const starsCont = [];
		let index = 0;
		for (let goldIndex = 0; goldIndex <= stars; goldIndex++) {
			starsCont.push(
				<img
					id={goldIndex}
					alt="quan"
					src={star}
					style={{ cursor: isEditable ? 'pointer' : '' }}
					onClick={() =>
						(function () {
							if (isEditable) {
								setStars(Number(goldIndex));
							}
						})(goldIndex)
					}
					className="messengerReview_cont_starsCont_star"
				/>,
			);
			index++;
		}
		for (let greyIndex = 0; greyIndex < 4 - stars; greyIndex++) {
			starsCont.push(
				<img
					id={greyIndex}
					alt="quan"
					style={{ cursor: isEditable ? 'pointer' : '' }}
					src={star_grey}
					onClick={() =>
						(function () {
							if (isEditable) {
								setStars(Number(stars + 1 + greyIndex));
							}
						})(greyIndex)
					}
					className="messengerReview_cont_starsCont_star"
				/>,
			);
			index++;
		}
		return starsCont;
	}

	function insertBadges() {
		const badges = [];
		let parsedReasons =
			!isEditable && props.reviewObj?.rating_reasons ? props.reviewObj?.rating_reasons : ReviewReasons;
		if (parsedReasons) {
			for (let index = 0; index < parsedReasons.length; index++) {
				badges.push(
					<IonBadge
						id={'badge' + index}
						style={{ cursor: isEditable ? 'pointer' : 'auto' }}
						className={
							isEditable ? 'messengerReview_cont_badgeCont_badge' : 'messengerReview_cont_badgeCont_badge-selected'
						}
						color="primary"
						onClick={(e) => addBadge(e, index)}
					>
						{parsedReasons[index]}
					</IonBadge>,
				);
			}
		}
		return badges;
	}

	function addBadge(e, i) {
		let badge = e.target;
		if (isEditable) {
			let newValue = ReviewReasons[i];
			let newArray = [...ratingReasons, newValue];
			if (ratingReasons.includes(newValue)) {
				newArray = newArray.filter((val) => val !== newValue);
			}
			badge.classList.toggle('messengerReview_cont_badgeCont_badge-selected');
			setRatingReasons(newArray);
		}
	}

	function changeCommentHandler(e) {
		setComment(e.target.value);
	}

	return (
		<VisibilitySensor onChange={componentVisibilitySensor}>
			<div className="messengerReview">
				<div className="messengerReview_animationCont" ref={animBox1}></div>
				<div className="messengerReview_cont">
					<p className="messengerReview_cont_label">
						{isEditable
							? 'How was ' + props.artist.fullname + "'s answer?"
							: 'Thank you! The rating\nhas been recorded.'}
					</p>
					{isEditable && <p className="messengerReview_cont_sublabel">Rating will be shared with the artist.</p>}
					<div className="messengerReview_cont_starsCont">
						{getStars(stars)}
						{isEditable && <span className="messengerReview_cont_starsCont_text">{ReviewStrings[stars] + '!'}</span>}
					</div>
					{comment && isEditable === false && <div className="messengerReview_cont_text">{comment}</div>}
					{isEditable && (
						<div className="messengerReview_cont_commentTextAreaCont">
							<span className="messengerReview_cont_commentTextAreaCont_label">Tell your artist more...</span>
							<textarea
								className="messengerReview_cont_commentTextAreaCont_input"
								value={comment}
								onChange={changeCommentHandler}
							></textarea>
						</div>
					)}
					{ratingReasons && (
						<div className="messengerReview_cont_badgeCont">
							<p className="messengerReview_cont_badgeCont_label">
								{Number(stars) === 4 ? 'What were you satisfied with?' : 'What went wrong?'}
							</p>
							{insertBadges()}
						</div>
					)}
					{isEditable && (
						<div
							onClick={handleReviewSend}
							className={`messengerReview_cont_submitBtn button ${
								!enabledBtn ? 'messengerReview_cont_submitBtn-disabled' : ''
							}`}
						>
							<p>SEND</p>
						</div>
					)}
				</div>
				<div className="messengerReview_footer">
					<img alt="quan" className="messengerReview_footer_img" src={logo} />
					<span className="messengerReview_footer_text">Review</span>
				</div>
			</div>
		</VisibilitySensor>
	);
};

export default MessengerReviewComponent;
