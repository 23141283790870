import { AppDispatch } from 'state/createStore';
import axios from 'axios';
import { GET_MESSAGES } from 'ServerAPI/api';
import { Message } from 'types/message';
import { getMessages } from './action';

export const fetchMessages = (id: string | number, data?: Message) => async (dispatch: AppDispatch) => {
	if (data) {
		dispatch(getMessages(data));
		return;
	}
	try {
		const url = `${GET_MESSAGES}?conversationId=${id}`;
		const {
			data: { data },
		} = await axios.get(url);
		dispatch(getMessages(data));
	} catch (error) {
		console.log(error);
	}
};
