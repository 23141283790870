import React from "react";
import "./notifyMessageComponent.scss";
import {
  TweenMax as TM,
  ScrollToPlugin,
  Power2,
  Power3,
  TimelineMax
} from "gsap/all";

class NotifyMessageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
      type: "error",
      showMessage: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setState({ text: this.props.text });
    }
    if (prevProps.type !== this.props.type) {
      this.setState({ type: this.props.type });
    }
    if (prevProps.showMessage !== this.props.showMessage) {
      this.setState({ showMessage: this.props.showMessage });
      if (this.props.showMessage) {
        this.showMessage();
      }
    }
  }

  componentDidMount() {
    this.setState({
        text: this.props.text,
        type: this.props.type
    })
  }

  showMessage = () => {
    var tl = new TimelineMax({ delay: 0 });
    tl.fromTo(
      this.notifyMessage,
      0.5,
      { opacity: 0, transform: "translateY(-10%)" },
      {
        opacity: 1,
        delay: 0.2,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    ).to(
      this.notifyMessage,
      0.5,
      {
        opacity: 0,
        transform: "translateY(-10%)",
        ease: Power2.easeOut,
        onComplete: function () {
          this.props.setShowMessage(false);
        }.bind(this)
      },
      "3"
    );
  };

  render() {
    return (
      <div
        ref={ref => (this.notifyMessage = ref)}
        className="notifyMessageComponent"
        style={{
          backgroundColor: this.state.type === "error" ? "#FF5862" : "#41935F"
        }}>
        <p>{this.state.text}</p>
      </div>
    );
  }
}

export default NotifyMessageComponent;
