import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'react-avatar';
import autosize from 'autosize';
import $ from 'jquery';
import { navigate } from 'gatsby';
import lottie from 'lottie-web';
import moment from 'moment';
import isBase64 from 'is-base64';
import 'balloon-css';
import { isBrowser, isMobile, isDesktop } from 'react-device-detect';
import insertTextAtCursor from 'insert-text-at-cursor';
import Carousel from 'react-material-ui-carousel';
//
import { getArtist, setSeenConversationByCustomer, createMessage } from '../utils/api.js';

import getStripe from '../utils/stripejs.js';
import { DataURIToBlob } from '../utils/tools.js';
//
import CoachmarkComponent from '../components/coachmarkComponent/coachmarkComponent.js';
import Checkout from '../checkoutComponent/checkout.js';
import MessageList from '../MessageList/index.js';
import MessengerIntroSelectComponent from '../messegerIntroSelect/messengerIntroSelectComponent.js';
import NotifyMessageComponent from '../notifyMessageComponent/notifyMessageComponent.js';
import VideoPlayer from '../../components/molecules/VideoPlayer/VideoPlayer.js';
//
import logo from '../assets/images/logo/logo-morphing-white.gif';
import cameraBtn from '../assets/images/icons/btn_camera.png';
import cameraBtnHover from '../assets/images/icons/btn_camera_hover.png';
import videoFilled from '../assets/images/icons/videoFilled.svg';
import deleteVideo from '../assets/images/icons/removeFile.svg';
import backBtn from '../assets/images/icons/back_btn.svg';
import backBtnHover from '../assets/images/icons/back_btn_hover.svg';
import playBox from '../assets/images/icons/play_box.svg';
import quanLogo from '../assets/images/logo/logo_footer.svg';
import report from '../assets/images/icons/report.svg';
import avatarPlaceholder from '../assets/images/icons/avatar_placeholder.svg';
import verifiedImg from '../assets/images/icons/verified.svg';
import './messenger.scss';
//
// import 'emoji-mart/css/emoji-mart.css';
//
import animationData2 from './loader.json';
import animationData3 from './loader_justLine.json';
import { DASHBOARD } from 'router/routes';
import {
	fetchConversation,
	fetchMessagesOfCustomerAndArtist,
	handleConversationPayment,
	setSeenConversation,
} from 'state/common/conversations/thunk';
import { selectArtistForConversation } from 'state/common/conversations/selector';
import { setPageLoader } from '../../state/common/pageLoader/thunk';
//

let animObj1 = null;
let animObj2 = null;
let animObj3 = null;

class MessangerComponent extends React.Component {
	constructor(props) {
		super(props);
		this.user = this.props.user;
		this.state = {
			artist: {
				id: null,
				fullname: 'Matt Halpern',
				name: '',
				price: '',
				picture: '',
				profile_video_main: '',
				carouselItems: [],
			},
			showEmojis: false,
			conversationId: null,
			success: false,
			selectedChatPrice: 0,
			loading: true,
			loader: false,
			showNotifMsg: false,
			backButtonEnter: false,
			cameraButtonEnter: false,
			errorMsg: 'Server error occoured.',
			initialMessage: true,
			introSelect: true,
			focusedMsgBox: false,
			promoCode: null,
			messageBoxValue: '',
			message: '',
			conversationDenied: null,
			declineReason: '',
			artistUrlTag: '',
			showPricingModal: 'none',
			videoName: 'Upload your video',
			closedConversation: false,
			chatType: null,
			showSeccondCoachmark: false,
			previewUploadingTextMessage: null,
			previewUploadVideoElements: [],
			previewUploadingVideos: [],
			uploadingMsgObj: null,
			disableCheckoutButton: true,
		};
	}

	componentDidMount() {
		this.props.setPageLoader(true, 'messenger');
		this.prepareReviewItems();
		if (this.props.conversation) {
			this.setConversationToStates(this.props.conversation);
		}
		autosize(document.querySelectorAll('textarea'));
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errorUploading !== this.props.errorUploading) {
			this.setState({ errorUploading: nextProps.errorUploading });
			this.setErrorUploadingElements();
		}
		if (nextProps.conversation !== this.props.conversation) {
			this.setConversationToStates(nextProps.conversation);
		}
	}

	setConversationToStates = (conversation) => {
		if (conversation) {
			this.getConversationData(conversation.artist?.id);
			this.setState({
				initialMessage: !conversation.id,
				introSelect: !conversation.type,
				conversationType: conversation.type,
				conversationDenied: conversation.declined,
				auto_close_at: conversation.auto_close_at,
			});
			this.setState({
				artist: {
					id: conversation.artist?.id,
					fullname: conversation.artist?.fullname,
					name: conversation.artist?.firstname,
					question_price: conversation.artist?.question_price,
					oneonone_price: conversation.artist?.oneonone_price,
					picture: conversation.artist?.picture
						? this.returnCDNMediaLink(conversation.artist?.picture)
						: avatarPlaceholder,
					profile_video_main: conversation.artist?.profile_video_main ? conversation.artist?.profile_video_main : '',
					link_tag: conversation.artist?.link_tag,
				},
			});
			this.props.setSeenConversation(conversation.id);
		}
		//this.checkAndShowUploadingMessages();
		// 	this.getAutomaticalyClosedTimeLeft(this.state.auto_close_at);
		// 	setTimeout(() => {
		// 		let { scrollHeight } = document.body;
		// 		if (this.state.closedConversation === 1 && this.state.conversationRating == null) {
		// 			scrollHeight = scrollHeight - 500 - window.innerHeight;
		// 		}
		// 		window.scrollTo(0, scrollHeight);
		// 	}, 1000);
		// }, 50);
		this.props.setPageLoader(false);
	};

	showEmojis = (e) => {
		this.setState(
			{
				showEmojis: true,
			},
			() => document.addEventListener('click', this.closeMenu),
		);
	};

	closeMenu = (e) => {
		console.log(this.emojiPicker);
		if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
			this.setState(
				{
					showEmojis: false,
				},
				() => document.removeEventListener('click', this.closeMenu),
			);
		}
	};

	getProductName = () => {
		switch (this.state.conversationType) {
			case 1:
				return 'QUAN - Question';
				break;
			case 2:
				return 'QUAN - OneOnOne';
				break;
			default:
				break;
		}
	};

	callBackForPricingModalShow = (show) => {
		this.setState({ showPricingModal: show });
	};

	returnCDNMediaLink = (url) => {
		var url = new URL(url);
		url.hostname = 'd121ftohl5zq6b.cloudfront.net';
		return url.toString();
	};

	getConversationData = (id) => {
		const artistId = id;
		this.props.fetchMessagesOfCustomerAndArtist(artistId);
	};

	createConversationResponseHandler = async (result) => {
		var data = result.data.data;
		if (data) {
			if (data.paid === true) {
				this.setState({ success: true });
			} else {
				const stripe = await getStripe();
				return stripe.redirectToCheckout({ sessionId: data.sessionId });
			}
		}
	};

	createConversationFunc = () => {
		const data = {
			message: this.state.message ? this.state.message : null,
			artistId: this.props.conversation.artist.id,
			file: this.state.video ? this.state.video : null,
			chatType: this.state.chatType,
			promoCode: this.state.promoCode ? this.state.promoCode : null,
		};

		this.props.handleConversationPayment(data, this.createConversationResponseHandler);
	};

	successPay = (value) => {
		if (value) {
			this.createConversationFunc();
		} else {
			this.setState({
				showNotifMsg: true,
				errorMsg: 'Purchase failed.',
			});
		}
	};

	setLoadingLottie = () => {
		animObj2 = lottie.loadAnimation({
			container: this.loaderBox, // the dom element that will contain the animation
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: animationData2, // the path to the animation json
		});
	};

	prepareReviewItems = () => {
		const carouselItems = [
			{
				avatar: this.preloadImage('https://quan-dev-processed.s3.amazonaws.com/avatars/MzbvDAnUsN6WVYbWTpZSC7_avatar'),
				name: 'Sophie',
				text: '”Thank you so much! Finally, I understand how it works during live shows .”',
			},
			{
				avatar: this.preloadImage('https://quan-dev-processed.s3.amazonaws.com/avatars/A5igcArigpaRS7ZJwNcuUJ_avatar'),
				name: 'JJ',
				text: '”Always professional and insightful. I recommend everyone to learn from you .”',
			},
			{
				avatar: this.preloadImage('https://quan-dev-processed.s3.amazonaws.com/avatars/3tBsZZhUrfZCGHaGxpmjoZ_avatar'),
				name: 'Mark',
				text: "”Thank you so much, the look on my son's face was priceless. You are his biggest inspiration .”",
			},
			{
				avatar: this.preloadImage('https://quan-dev-processed.s3.amazonaws.com/avatars/EhMFjSfYYgxLKhvmjZaJiJ_avatar'),
				name: 'Zach',
				text: '”Amazing! Lightening fast turn around time and total day maker. Thank you! ”',
			},
			{
				avatar: this.preloadImage('https://quan-dev-processed.s3.amazonaws.com/avatars/MpqwWXvy7vZt4uL1KtHQaS_avatar'),
				name: 'Zoe',
				text: '”I teared up watching this. Your bandmates are going to love it. Keep it up! ”',
			},
		];
		this.setState({ carouselItems });
	};

	preloadImage = (url) => {
		const img = new Image();
		img.src = url;
		return img;
	};

	triggerVideoUploadDialogue = () => {
		$('#singleVideo').trigger('click');
	};

	initialTextAreaFocus = (e) => {
		// document.getElementById("tooltipCont").style.display = "none";
	};

	shouldEnableButton = () => {
		if (this.state.message.length > 0) {
			this.setState({ disableCheckoutButton: false });
		} else {
			this.setState({ disableCheckoutButton: true });
		}
	};

	sendAndPay = () => {
		// this.setState({ success: true });
		this.successPay(true);
	};

	addEmoji = (e) => {
		const emoji = e.native;
		insertTextAtCursor(this.messageBoxInput, ` ${emoji} `);
		// this.setState({
		//   messageBoxValue: this.state.messageBoxValue + emoji,
		// });
	};

	handleMessageChange = (event) => {
		this.setState({ message: event.target.value }, () => {
			if (this.state.initialMessage && !this.state.introSelect) {
				this.shouldEnableButton();
			}
		});
	};

	uploadVideo = (e) => {
		if (e !== undefined && !this.state.video) {
			const files = Array.from(e.target.files);
			const reader = new FileReader();
			if (files.length > 0) {
				reader.readAsDataURL(files[0]);
				reader.onloadend = function (e) {
					if (e.total <= 200000000) {
						this.setState({
							video: reader.result,
							videoName: files[0].name,
						});
					} else {
						this.setState({
							showNotifMsg: true,
							errorMsg: 'Video size needs to be less than 200mb.',
						});
					}
				}.bind(this);
			}
		}
	};

	removeVideo = () => {
		this.setState({
			videoName: 'Upload your video',
			video: null,
		});
	};

	selectedChatType = (num, promoCodeObj) => {
		if (num && promoCodeObj == null) {
			let selectedPrice = 0;
			switch (num) {
				case 1:
					selectedPrice = this.props.conversation.artist.question_price;
					break;
				case 2:
					selectedPrice = this.props.conversation.artist.oneonone_price;
					break;
				default:
					break;
			}
			this.setState({
				introSelect: false,
				chatType: num,
				selectedChatPrice: selectedPrice,
				conversationType: num,
			});
		} else if (promoCodeObj != null) {
			this.setState({
				introSelect: false,
				chatType: num,
				selectedChatPrice: promoCodeObj.price,
				promoCode: promoCodeObj.code,
				conversationType: num,
			});
		}
		window.scrollTo(0, document.body.scrollHeight);
		setTimeout(() => {
			autosize(document.getElementById('messageTextArea'));
		}, process.env.loader_time);
	};

	setShowMessage = (val) => {
		this.setState({ showNotifMsg: val });
	};

	handleMessageBoxChange = (event) => {
		this.setState({ messageBoxValue: event.target.value });
	};

	addVideoToUploadingPreviewContainer = (video) => {
		const arrayOfVideos = this.state.previewUploadingVideos;
		arrayOfVideos.push(video);
		this.setState({
			previewUploadingVideos: arrayOfVideos,
		});
		this.setVideoUploadingPreviewElements(arrayOfVideos);
	};

	setVideoUploadingPreviewElements = (videos) => {
		const elements = videos.map(function (video, index) {
			let processedVideo = null;
			if (isBase64(video)) {
				processedVideo = video;
			} else {
				processedVideo = DataURIToBlob(video);
			}
			let videoObj = null;
			if (typeof window !== 'undefined') {
				videoObj = window.URL.createObjectURL(processedVideo);
			}
			return (
				<div className="messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt">
					<div className="messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader">
						<div className="messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader_lottie" />
						<img
							alt="quan"
							className="messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader_error"
							src={report}
						/>
						<p className="messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader_text">
							Uploading...
						</p>
					</div>
					{/* <img alt='quan'
              className="messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_download"
              src={downloadFile}
              onClick={() => this.saveVideo(videoObj)}
            /> */}
					<video
						width="110"
						height="220"
						data-index={index}
						src={videoObj}
						autoPlay={false}
						muted
						loop
						playsInline
						onLoadedMetadata={(e) => {
							e.currentTarget.currentTime = 0.2;
						}}
						className=""
						style={{ objectFit: 'cover' }}
					/>
				</div>
			);
		});
		this.setState({ previewUploadVideoElements: elements });
		setTimeout(() => {
			const videosElements = document.getElementsByClassName(
				'messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader_lottie',
			);
			for (const element of videosElements) {
				$(element).html('');
				animObj3 = lottie.loadAnimation({
					container: element, // the dom element that will contain the animation
					renderer: 'svg',
					loop: true,
					autoplay: true,
					animationData: animationData3, // the path to the animation json
				});
			}
			window.scrollTo(0, document.body.scrollHeight);
		}, process.env.loader_time);
	};

	setErrorUploadingElements = () => {
		$('.messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader_text').text(
			'Network issue',
		);
		$('.messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader_lottie').css(
			'display',
			'none',
		);
		$('.messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_download').css(
			'display',
			'block',
		);
		$('.messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont_previewCnt_loader_error').css(
			'display',
			'block',
		);
	};

	removeVideoUploadingPreviewElements = () => {
		console.log('Remove preview elements');
		this.setState({
			previewUploadVideoElements: [],
			previewUploadingVideos: [],
			previewUploadingTextMessage: null,
		});
		window.scrollTo(0, document.body.scrollHeight);
	};

	checkAndShowUploadingMessages = () => {
		if (this.state.uploadingMsgObj !== null) {
			if (this.state.uploadingMsgObj.files !== null && this.state.uploadingMsgObj.files.length > 0) {
				if (this.state.uploadingMsgObj.message !== '') {
					this.setState({
						previewUploadingTextMessage: this.state.uploadingMsgObj.message,
					});
				}
				this.state.uploadingMsgObj.files.forEach(
					function (video) {
						this.addVideoToUploadingPreviewContainer(video);
					}.bind(this),
				);
			}
		}
	};

	getButtonLabelText = () => {
		if (Number(this.state.selectedChatPrice) > 0) {
			return `CONTINUE AND PAY $${String(this.state.selectedChatPrice)}`;
		}
		return 'CONTINUE FOR FREE';
	};

	sendMessageFromMsgBox = () => {
		if (this.state.messageBoxValue !== '' || this.state.video !== null) {
			if (!this.state.loader) {
				this.setState({ loader: true });
				const data = {
					message: this.state.messageBoxValue,
					customerId: this.user.id,
					artistId: this.props.conversation.artist.id,
					conversationId: this.props.conversation.id,
					conversationType: this.state.conversationType,
					files: this.state.video ? [this.state.video] : [],
					sender: 'customer',
					shareVideo: 1,
					draft: 0,
				};
				if (this.state.video) {
					this.setState({
						uploadingMsgObj: {
							message: this.state.messageBoxValue,
							files: [this.state.video],
						},
						previewUploadingTextMessage: this.state.messageBoxValue ? this.state.messageBoxValue : null,
					});
					setTimeout(() => {
						const { video } = this.state;
						this.addVideoToUploadingPreviewContainer(video);
						this.setState({
							loader: false,
							messageBoxValue: '',
						});
						this.removeVideo();
					}, 100);
				}
				createMessage(data).then(() => {
					this.setState({
						loader: false,
						messageBoxValue: '',
						update: !this.state.update,
						auto_close_at: null,
					});
					this.removeVideo();
					this.removeVideoUploadingPreviewElements();
					if (this.messageBoxInput) {
						this.messageBoxInput.style.height = '24px';
					}
					this.forceUpdate();
					if (this.messageBoxInput) {
						setTimeout(() => {
							window.scrollTo(0, document.body.scrollHeight);
						}, 100);
					}
				});
			}
		}
	};

	getAutomaticalyClosedTimeLeft = (automaticallyCloseTime) => {
		const unixTime = moment.unix(parseInt(automaticallyCloseTime));
		const today = moment();
		const result = unixTime.diff(today, 'hours');
		this.setState({ automaticalyClosedTimeLeft: result.toString() });
	};

	onBlur = () => {
		this.setState({ focusedMsgBox: false });
	};

	onFocus = () => {
		this.setState({ focusedMsgBox: true });
	};

	coachmarkButtonClick = () => {
		this.setState({ showSeccondCoachmark: true });
	};

	render() {
		return (
			<>
				{this.state.introSelect && (
					<MessengerIntroSelectComponent
						artist={this.props.conversation?.artist}
						selectedChatType={this.selectedChatType}
					/>
				)}

				{!this.state.introSelect && (
					<div
						style={{
							flexDirection: this.state.initialMessage ? 'row' : 'column',
						}}
						className={this.state.initialMessage ? 'messenger' : 'messenger messenger_center'}
					>
						{((!this.state.initialMessage && isBrowser) || isMobile) && (
							<div className="messenger_header">
								<img
									alt="quan"
									className="messenger_header_backBtn"
									onClick={() => navigate(DASHBOARD)}
									onMouseEnter={() => this.setState({ backButtonEnter: true })}
									onMouseLeave={() => this.setState({ backButtonEnter: false })}
									src={this.state.backButtonEnter ? backBtnHover : backBtn}
								/>
								<div
									className="messenger_header_avatarCont"
									onClick={() => navigate(`/${this.props.conversation.artist.link_tag}`)}
								>
									<Avatar size="32" round src={this.props.conversation.artist.picture} />{' '}
									<p className="t1 messenger_header_avatarCont_name">{this.props.conversation.artist.fullname}</p>
								</div>
							</div>
						)}
						{this.state.initialMessage && isBrowser && (
							<div className="messenger_infoCont">
								<img
									alt="quan"
									className="messenger_infoCont_backBtn"
									onClick={() => navigate(DASHBOARD)}
									onMouseEnter={() => this.setState({ backButotnEnter: true })}
									onMouseLeave={() => this.setState({ backButotnEnter: false })}
									src={this.state.backButotnEnter ? backBtnHover : backBtn}
								/>
								<h3 className="messenger_infoCont_header">
									Ask <Avatar size="32" round src={this.props.conversation.artist.picture} />{' '}
									{this.props.conversation.artist.fullname}
									<br />
									your question
								</h3>
								{isDesktop && (
									<div className="messenger_infoCont_verified">
										<img alt="quan" className="messenger_infoCont_verified_logo" src={verifiedImg} />{' '}
										<span className="messenger_infoCont_verified_text">Verified</span>
									</div>
								)}
								{this.props.conversation.artist.profile_video_main && (
									<VideoPlayer
										className="messenger_infoCont_video"
										videoUrl={this.props.conversation.artist.profile_video_main}
										showSkip
										controlsAtPreview
										messengerIntroVideo={false}
										fullscreen={false}
									/>
								)}
								{!this.props.conversation.artist.profile_video_main && (
									<div className="messenger_infoCont_mainVideoPlaceholder">
										<img alt="quan" className="messenger_infoCont_mainVideoPlaceholder_img" src={playBox} />
									</div>
								)}
								<div className="messenger_infoCont_reviewCont">
									<div className="messenger_infoCont_reviewCont_header">
										<img alt="quan" src={quanLogo} />
										<span>Reviews</span>
									</div>
									<Carousel
										interval="6000"
										duration="2000"
										autoPlay="false"
										navButtonsProps={{
											style: {
												display: 'none',
											},
										}}
										indicatorIconButtonProps={{
											style: {
												margin: '6px',
												color: '#1E1E1E',
											},
										}}
										activeIndicatorIconButtonProps={{
											style: {
												backgroundColor: '#F1E1D5', // 2
												color: '#F1E1D5',
											},
										}}
										indicatorContainerProps={{
											style: {
												marginTop: '10px', // 5
												marginLeft: '5px', // 5
												textAlign: 'left', // 4
											},
										}}
									>
										{this.state.carouselItems.map((item, i) => (
											<div className="messenger_infoCont_reviewCont_item">
												<div className="messenger_infoCont_reviewCont_item_nameAvatarCont">
													<img alt="quan" src={item.avatar.src} alt="review avatar" />
													<span>{item.name}</span>
												</div>
												<p className="messenger_infoCont_reviewCont_item_text">{item.text}</p>
											</div>
										))}
									</Carousel>
								</div>
							</div>
						)}
						<div className="messenger_messageBoard">
							{!this.state.initialMessage && (
								<div className="messenger_messageBoard_messageListCont">
									<MessageList
										conversation={this.props.conversation}
										update={this.state.update}
										removeVideoUploadingPreviewElements={this.removeVideoUploadingPreviewElements}
									/>
									{(this.state.previewUploadingTextMessage !== null ||
										this.state.previewUploadVideoElements.length > 0) && (
										<div
											className="messenger_messageBoard_messageListCont_uploadingWrapper"
											style={{
												display: Boolean(this.props.conversation.closed) ? 'none' : 'flex',
											}}
										>
											{this.state.previewUploadingTextMessage !== null && (
												<div className="messenger_messageBoard_messageListCont_uploadingWrapper_addedTextMsgCont">
													<div className="message mine  ">
														<div className="bubble-container">
															<div className="bubble-container_inner">
																<div className="bubble" title="Today">
																	{this.state.previewUploadingTextMessage}
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
											<div className="messenger_messageBoard_messageListCont_uploadingWrapper_addedVideosCont">
												{this.state.previewUploadVideoElements}
											</div>
										</div>
									)}
									{!Boolean(this.props.conversation.closed) && !Boolean(this.props.conversation.answered) && (
										<div className="message-list_closedconversation" style={{ paddingBottom: '120px' }}>
											{this.props.conversation.declined == null && (
												<p className="message-list_closedconversation_text">
													We will notify you when {this.props.conversation.artist.fullname} accepts the request.
												</p>
											)}
											{this.props.conversation.declined == 0 && (
												<p className="message-list_closedconversation_text">
													This request was accepted by {this.props.conversation.artist.fullname}. Please wait for an
													answer.
												</p>
											)}
										</div>
									)}
								</div>
							)}
						</div>

						{this.state.initialMessage && (
							<div className="initialMessageCont">
								<div className="initialMessageCont_wrapper">
									<div className="initialMessageCont_wrapper_inputBoard">
										<textarea
											id="messageTextArea"
											name="initialText"
											placeholder={`Dear ${this.props.conversation.artist.fullname}, I would like to know...`}
											onFocus={this.initialTextAreaFocus}
											onChange={this.handleMessageChange}
											value={this.state.message}
											ref={(ref) => (this.textarea = ref)}
										/>
										<div className="initialMessageCont_wrapper_inputBoard_coachmark">
											<CoachmarkComponent
												text="Type your message and ask your favorite artist anything you want."
												position="bottom"
												field="4"
												buttonText="NEXT 1/2"
												buttonClickCallback={this.coachmarkButtonClick}
												showMessage
											/>
										</div>
										<div className="initialMessageCont_wrapper_inputBoard_uploadCnt">
											<img
												alt="quan"
												onClick={this.triggerVideoUploadDialogue}
												onMouseEnter={() => this.setState({ cameraButtonEnter: true })}
												onMouseLeave={() => this.setState({ cameraButtonEnter: false })}
												className={
													!this.state.video
														? 'initialMessageCont_wrapper_inputBoard_uploadCnt_cameraIcon'
														: 'initialMessageCont_wrapper_inputBoard_uploadCnt_cameraIcon initialMessageCont_wrapper_inputBoard_uploadCnt_cameraIcon_svg'
												}
												src={
													!this.state.video ? (this.state.cameraButtonEnter ? cameraBtnHover : cameraBtn) : videoFilled
												}
											/>
											{this.state.showSeccondCoachmark && (
												<div className="initialMessageCont_wrapper_inputBoard_uploadCnt_coachmark">
													<CoachmarkComponent
														text="Feel free to attach a video from your library."
														position={isBrowser ? 'top' : 'bottom_left'}
														field="5"
														showMessage
													/>
												</div>
											)}
											<label
												className="t3 initialMessageCont_wrapper_inputBoard_uploadCnt_addPhotoLbl"
												htmlFor="singleVideo"
											>
												{this.state.videoName}
											</label>
											{!this.state.video && (
												<input
													type="file"
													id="singleVideo"
													readOnly
													accept="video/*"
													onChange={this.uploadVideo}
													style={{ visibility: 'hidden' }}
												/>
											)}
											{this.state.video && (
												<img
													alt="quan"
													onClick={this.removeVideo}
													className="t3 initialMessageCont_wrapper_inputBoard_uploadCnt_deleteVideoBtn"
													src={deleteVideo}
												/>
											)}
											{isMobile && (
												<Checkout
													class="button initialMessageCont_wrapper_inputBoard_uploadCnt_btn"
													price={this.state.selectedChatPrice}
													product_name={this.getProductName()}
													artistId={this.props.conversation.artist.id}
													label="CONTINUE"
													disabled={this.state.disableCheckoutButton}
													sendAndPay={this.sendAndPay}
												/>
											)}
										</div>
									</div>
								</div>
								{isBrowser && (
									<div className="initialMessageCont_bottomCont">
										<Checkout
											class="button initialMessageCont_bottomCont_button"
											price={this.state.selectedChatPrice}
											product_name={this.getProductName()}
											artistId={this.props.conversation.artist.id}
											label={this.getButtonLabelText()}
											disabled={this.state.disableCheckoutButton}
											sendAndPay={this.sendAndPay}
										/>
									</div>
								)}
							</div>
						)}

						{this.state.success === true && (
							<div className="messenger_succesPaymentComponent">
								<img alt="quan" className="messenger_succesPaymentComponent_logo" src={logo} />
								<div className="messenger_succesPaymentComponent_wrapper">
									<Avatar
										size="100"
										round
										src={this.props.conversation.artist.picture}
										className="messenger_succesPaymentComponent_wrapper_avatar"
									/>
									<h3>{this.props.conversation.artist.fullname} has received your question!</h3>
									<p className="t3">
										{this.props.conversation.artist.fullname} will get back to you within <text>5 days</text>. We'll
										notify you when your answer is ready!
									</p>
									<div className="button" onClick={() => navigate(DASHBOARD)}>
										CONTINUE BROWSING
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
		conversation: state.conversations.conversation,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			fetchConversation,
			fetchMessagesOfCustomerAndArtist,
			setPageLoader,
			handleConversationPayment,
			setSeenConversation,
		},
		dispatch,
	);

MessangerComponent.propTypes = {
	user: PropTypes.object,
	conversation: PropTypes.object,
	declineReason: PropTypes.string,
	artist: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessangerComponent);
