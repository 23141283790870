// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--pFFM6";
export var artistSlider = "style-module--artistSlider--Asud4";
export var artistsSection = "style-module--artistsSection--abuS0";
export var banner = "style-module--banner---ce-U";
export var banner_container = "style-module--banner_container--AA2v+";
export var borders = "style-module--borders--DArpf";
export var chromeframe = "style-module--chromeframe--LXlFH";
export var container = "style-module--container--sHr2a";
export var container2 = "style-module--container2--ftdRJ";
export var createTitle = "style-module--createTitle--1bNgG";
export var discoverSection1 = "style-module--discoverSection1--KU338";
export var discoverSection2 = "style-module--discoverSection2--vBe8g";
export var errorText = "style-module--errorText--BYR0x";
export var headerContainer = "style-module--headerContainer---Xv2B";
export var homeContainer = "style-module--homeContainer--eipiI";
export var overlay = "style-module--overlay--012ww";
export var personalBtn = "style-module--personalBtn--IOd7n";
export var personalCreate = "style-module--personalCreate--L8cC7";
export var personalSection = "style-module--personalSection--mWlXL";
export var personalized = "style-module--personalized--mGfvw";
export var personalizedContainer = "style-module--personalizedContainer--7RXLm";
export var questionSliderItem = "style-module--questionSliderItem--VqxGt";
export var searchContainer = "style-module--searchContainer--tZ9e2";
export var searchContainer2 = "style-module--searchContainer2--V3F-t";
export var skillSetSection = "style-module--skillSetSection--kFby+";
export var textDefault = "style-module--text-default--jCq3g";
export var textInput = "style-module--text-input--FaXwY";
export var videoJs = "style-module--video-js--5Qvf3";
export var videoPlayerButton = "style-module--videoPlayerButton--8W5si";
export var videoPlaying = "style-module--videoPlaying--szVol";
export var videoSection = "style-module--videoSection--ZB4A-";
export var videoWrapper = "style-module--videoWrapper--Vee4d";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--ez887";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--Xxep+";
export var vjsPoster = "style-module--vjs-poster--ZKBiP";