import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, navigate } from 'gatsby';

import './profileComponent.scss';
import Avatar from 'react-avatar';
import logoutBtn from '../../assets/images/icons/logout_btn.svg';
import logoutBtnHover from '../../assets/images/icons/logout_btn_hover.svg';
import backBtn from '../../assets/images/icons/back_btn.svg';
import backBtnHover from '../../assets/images/icons/back_btn_hover.svg';
import { TweenMax as TM, Power3 } from 'gsap/all';
import { InView } from 'react-intersection-observer';
import ChangePassModal from '../changePassModal/changePassModal';
import lottie from 'lottie-web';
import animationData1 from '../../../assets/lotties/loaders/3.json';
import animationData2 from './loader_justLine.json';
import { getUser, changeUser } from 'state/common/auth/thunk';
import { logout } from 'state/common/auth/action';
import { logout as logoutAction } from '../../../services/auth';
import { setPageLoader } from '../../../state/common/pageLoader/thunk';
import { LOGIN_SCREEN } from 'router/routes';

let animObj1 = null;
let animObj2 = null;

class ProfileComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			loader: false,
			showForm: 'none',
			newPic: false,
			showNotifMsg: false,
			notifType: 'error',
			errorMsg: 'Server error occoured.',
			backButotnEnter: false,
			logoutButotnEnter: false,
			user: {
				avatarPic: null,
				fullname: '',
				email: 'this.state.user.email',
			},
		};
	}

	buttonClick = () => {
		this.props.callBackForFormShow('flex');
	};

	callBackForFormShow = (show) => {
		this.setState({ showForm: show });
	};

	componentDidMount() {
		this.props.setPageLoader(true, 'profile');
		this.props.getUser();
		animObj1 = lottie.loadAnimation({
			container: this.animBox1, // the dom element that will contain the animation
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: animationData1, // the path to the animation json
		});
		animObj2 = lottie.loadAnimation({
			container: this.loaderBox, // the dom element that will contain the animation
			renderer: 'svg',
			loop: true,
			autoplay: true,
			animationData: animationData2, // the path to the animation json
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.user !== this.props.user) {
			this.setState({
				fullname: nextProps.user.fullname,
				email: nextProps.user.email,
				loader: false,
				avatarPic: nextProps.user.avatar_url ? this.returnCDNMediaLink(nextProps.user.avatar_url) : null,
			});
			var userObj = {
				fullname: nextProps.user.fullname,
				email: nextProps.user.email,
				avatar: nextProps.user.avatar_url ? this.returnCDNMediaLink(nextProps.user.avatar_url) : null,
				id: nextProps.user.id,
			};
			setTimeout(() => {
				this.props.setPageLoader(false, 'profile');
			}, process.env.loader_time);
		}
	}

	uploadPicture = (e) => {
		if (e !== undefined) {
			const files = Array.from(e.target.files);
			var reader = new FileReader();
			if (files.length > 0) {
				reader.readAsDataURL(files[0]);
				reader.onloadend = function (e) {
					this.setState({
						avatarPic: reader.result,
						newPic: true,
					});
				}.bind(this);
			}
		}
	};

	changePassword = () => {
		this.setState({
			showForm: true,
		});
	};

	logoutFunc = () => {
		logoutAction();
		this.props.logout();
		navigate(LOGIN_SCREEN);
	};

	b64toBlob = (dataURI) => {
		var byteString = atob(dataURI.split(',')[1]);
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);

		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: 'image/jpeg' });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ loader: true });
		const data = {
			email: this.state.email,
			file: this.state.newPic ? this.state.avatarPic : null,
			fullname: this.state.fullname,
		};
		this.props
			.changeUser(data)
			.then(() => {
				// this.getProfileData();
				this.setState({
					errorMsg: 'Profile has been updated.',
					notifType: 'info',
					showNotifMsg: true,
				});
			})
			.catch(() => {
				this.setState({
					showNotifMsg: true,
					errorMsg: 'Failed to submit data.',
				});
			});
	};

	shouldEnableButton = () => {
		var button = document.getElementById('loginSubmitBtn');
		var email = document.getElementById('email');
		var password = document.getElementById('password');

		if (email.value.length > 0 && password.value.length > 0) {
			button.disabled = false;
			button.classList.add('submitButton_enabled');
		} else {
			button.classList.remove('submitButton_enabled');
		}
	};

	handleChange = (event) => {
		switch (event.target.id) {
			case 'profileEmail':
				this.setState({ email: event.target.value });
				break;
			case 'profileName':
				this.setState({ fullname: event.target.value });
				break;
			default:
				break;
		}
	};

	handleContentVisibility(inView) {
		if (inView && !this.state.playedOnce) {
			TM.fromTo(
				this.back,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0,
				},
			);
			TM.fromTo(
				this.title,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0,
				},
			);
			TM.fromTo(
				this.subtitle,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.1,
				},
			);
			TM.fromTo(
				this.basicInf,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.2,
				},
			);
			TM.fromTo(
				this.avatar,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.3,
				},
			);

			TM.fromTo(
				this.name,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.4,
				},
			);
			TM.fromTo(
				this.email,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.5,
				},
			);
			TM.fromTo(
				this.changePass,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.6,
				},
			);
			TM.fromTo(
				this.button,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.7,
				},
			);
			TM.fromTo(
				this.logout,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.8,
				},
			);
			this.setState({ playedOnce: true });
		}
	}

	returnCDNMediaLink = (url) => {
		var url = new URL(url);
		url.hostname = 'd121ftohl5zq6b.cloudfront.net';
		return url.toString();
	};

	setShowMessage = (val) => {
		this.setState({ showNotifMsg: val });
	};

	render() {
		return (
			<div className="profile">
				<InView threshold="0.3" onChange={(inView) => this.handleContentVisibility(inView)}>
					<div className="profile__cont">
						<h2 ref={(ref) => (this.title = ref)}>Edit profile</h2>
						<p ref={(ref) => (this.subtitle = ref)} className="t2 profile__cont_subtitle">
							Artists on Quan will get to know you with the info below.
						</p>
						<div ref={(ref) => (this.basicInf = ref)} className="profile__cont_lineCont">
							<h4>Basic information</h4>
						</div>
						<form className="profile__cont_form" name="message" onSubmit={(e) => this.handleSubmit(e)}>
							<div ref={(ref) => (this.avatar = ref)} className="profile__cont_form_avatarCont">
								<Avatar
									name={this.state.user.fullname}
									size="120"
									color={Avatar.getRandomColor('sitebase', ['#87D1AA', '#6277CB', '#FFC996', '#FCE2AE', '#FF7A86'])}
									fbColor="rgba(#020202,0.3)"
									round={true}
									maxInitials={2}
									textSizeRatio={3.7}
									src={this.state.avatarPic}
								/>
								<div>
									<label className="t3 profile__cont_form_avatarCont_addPhotoLbl" for="single">
										{this.state.avatarPic ? 'Change photo' : 'Add a photo'}
									</label>
									<input
										type="file"
										id="single"
										readOnly
										accept=".png, .jpg, .jpeg"
										onChange={this.uploadPicture}
										style={{ visibility: 'hidden' }}
									/>
								</div>
							</div>
							<div ref={(ref) => (this.name = ref)} className="profile__cont_form_input">
								<label for="name">FULL NAME</label>
								<input
									id="profileName"
									type="text"
									value={this.state.fullname}
									onChange={this.handleChange}
									name="name"
								/>
							</div>
							<div ref={(ref) => (this.email = ref)} className="profile__cont_form_input">
								<label for="email">YOUR E-MAIL</label>
								<input
									id="profileEmail"
									disabled={true}
									type="text"
									value={this.state.email}
									onChange={this.handleChange}
									name="email"
								/>
							</div>
							<p
								className="t3 profile__cont_form_forgot"
								ref={(ref) => (this.changePass = ref)}
								onClick={this.changePassword}
							>
								Change your password
							</p>
							<button ref={(ref) => (this.button = ref)} type="submit" className="button profile__cont_form_saveBtn">
								{!this.state.loader && <p>Done</p>}
								<div
									style={{
										display: this.state.loader === true ? 'flex' : 'none',
									}}
									ref={(ref) => (this.loaderBox = ref)}
									className="videoContainer_previewCnt_loader"
								></div>
							</button>
						</form>
						<img
							alt="quan"
							ref={(ref) => (this.logout = ref)}
							className="profile__cont_form_logoutBtn"
							onClick={this.logoutFunc}
							onMouseEnter={() => this.setState({ logoutButotnEnter: true })}
							onMouseLeave={() => this.setState({ logoutButotnEnter: false })}
							src={this.state.logoutButotnEnter ? logoutBtnHover : logoutBtn}
						/>
					</div>
					<img
						alt="quan"
						ref={(ref) => (this.back = ref)}
						className="profile__backButton"
						onClick={() => navigate(-1)}
						onMouseEnter={() => this.setState({ backButotnEnter: true })}
						onMouseLeave={() => this.setState({ backButotnEnter: false })}
						src={this.state.backButotnEnter ? backBtnHover : backBtn}
					/>
				</InView>
				<ChangePassModal callBackForFormShow={this.callBackForFormShow} showForm={this.state.showForm} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user,
		coachmarks: state.coachmarks,
	};
};

ProfileComponent.propTypes = {
	user: PropTypes.object,
	coachmarks: PropTypes.object,
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			logout,
			getUser,
			changeUser,
			setPageLoader,
		},
		dispatch,
	);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
