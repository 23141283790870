import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logo from '../assets/images/header/Logo.svg';
import './messengerIntroSelectComponent.scss';
import Avatar from 'react-avatar';
import { navigate } from 'gatsby';
import backBtn from '../assets/images/icons/back_btn.svg';
import backBtnHover from '../assets/images/icons/back_btn_hover.svg';
import noteIcon from '../assets/images/icons/note.svg';
import removeCodeIcon from '../assets/images/icons/remove_code.svg';
import { isBrowser, TabletView, isMobile, isDesktop, isTablet } from 'react-device-detect';
import 'balloon-css';
import { Link } from 'gatsby';
import { TweenMax as TM, ScrollToPlugin, Power2, Power3, TimelineMax } from 'gsap/all';
import { getPromoCode } from '../utils/api.js';
import { DASHBOARD } from 'router/routes';

class MessangerIntroSelectComponent extends React.Component {
	constructor(props) {
		super(props);
		this.user = this.props.user;
		this.state = {
			artist: {
				id: null,
				fullname: '',
				name: '',
				price: '',
				picture: '',
			},
			code: null,
			price: 0,
			codeApplied: false,
			incorrectCode: false,
			discountAmount: 0,
			selectedColumn: 2,
			backButotnEnter: false,
		};
	}

	componentDidMount() {
		this.setState({ artist: this.props.artist });
		this.setState({ price: this.props.artist.question_price });
	}

	applyPromoCode = () => {
		getPromoCode({ code: this.state.code })
			.then((res) => {
				console.log(res);
				var discount = 0;
				var newPrice = 0;
				if (Boolean(res.isFreePurchase)) {
					newPrice = 0;
					discount = this.state.price;
				} else if (res.unit == '$') {
					newPrice = Number(this.state.price) - Number(res.value);
					newPrice = newPrice < 0 ? 0 : newPrice;
					discount = Number(this.state.price) - Number(res.value) < 0 ? Number(this.state.price) : Number(res.value);
				} else if (res.unit == '%') {
					newPrice = Number(this.state.price) * ((100 - Number(res.value)) / 100);
					discount = Number(this.state.price) * (Number(res.value) / 100);
				}
				this.setState({
					codeApplied: true,
					discountAmount: discount,
					price: newPrice,
					incorrectCode: false,
				});
			})
			.catch((e) => {
				console.log(e);
				this.setState({
					codeApplied: false,
					discountAmount: 0,
					price: this.props.artist.question_price,
					incorrectCode: true,
				});
			});
	};

	removePromoCode = () => {
		this.setState({
			codeApplied: false,
			price: this.props.artist.question_price,
			code: '',
		});
	};

	handleCodeInputChange = (e) => {
		this.setState({ code: e.target.value });
		if (e.target.value == '') {
			this.setState({
				codeApplied: false,
				incorrectCode: false,
			});
		}
	};

	selectChatType = (num) => {
		if (this.state.codeApplied == false) {
			this.props.selectedChatType(num);
		} else {
			this.props.selectedChatType(num, { code: this.state.code, price: this.state.price });
		}
	};

	navigateToArtistProfile = () => {
		let params = new URLSearchParams(document.location.search.substring(1));
		if (params.get('artist')) {
			navigate('/' + params.get('artist'));
		} else {
			history.back();
		}
	};

	getArtistRequestPrice = () => {
		return this.state.price > 0 ? this.state.price : 'Free';
	};

	render() {
		return (
			<div className="messengerIntroSelect">
				{isBrowser && (
					<div className="messengerIntroSelect_header">
						<div className="messengerIntroSelect_header__cont">
							<div className="messengerIntroSelect_header__cont__firstCol">
								<img
									alt="quan"
									className="messengerIntroSelect_header__cont__firstCol_backBtn"
									onClick={() => this.navigateToArtistProfile()}
									onMouseEnter={() => this.setState({ backButotnEnter: true })}
									onMouseLeave={() => this.setState({ backButotnEnter: false })}
									src={this.state.backButotnEnter ? backBtnHover : backBtn}
								/>
								<Link className="messengerIntroSelect_header__cont_a" to={DASHBOARD} target="_blank">
									<img alt="quan" className="messengerIntroSelect_header__cont__firstCol_logo" src={logo}></img>
								</Link>
							</div>
						</div>
					</div>
				)}
				<div ref={(ref) => (this.selectCont = ref)} className="messengerIntroSelect_cont">
					<div className="messengerIntroSelect_cont_choicesCont">
						<div className="messengerIntroSelect_cont_choicesCont_column">
							<div className="messengerIntroSelect_cont_header">
								<div className="messengerIntroSelect_cont_header_avatarCont">
									<Avatar size="50" round={true} src={this.state.artist.picture} />{' '}
								</div>
								<p className="messengerIntroSelect_cont_header_name">Ask {this.state.artist.fullname} your question</p>
							</div>

							<span className="messengerIntroSelect_cont_choicesCont_column_description">
								Get a quick tip, short answer, or shoutout.
							</span>
							<div
								ref={(ref) => (this.hiddenCont1 = ref)}
								className="messengerIntroSelect_cont_choicesCont_column_hiddenCont"
							>
								<div
									className={`messengerIntroSelect_cont_choicesCont_column_hiddenCont_price ${
										this.state.codeApplied == true
											? 'messengerIntroSelect_cont_choicesCont_column_hiddenCont_price-applied'
											: ''
									}`}
								>
									{this.state.artist.question_price > 0 && (
										<span className="messengerIntroSelect_cont_choicesCont_column_hiddenCont_price_dollarSign">$</span>
									)}
									<span className="messengerIntroSelect_cont_choicesCont_column_hiddenCont_price_number">
										{this.getArtistRequestPrice()}
									</span>
									{this.state.codeApplied == true && (
										<span className="messengerIntroSelect_cont_choicesCont_column_hiddenCont_price_discountNumber">
											-${this.state.discountAmount}
										</span>
									)}
								</div>
								<div
									onClick={() => this.selectChatType(1)}
									className="button messengerIntroSelect_cont_choicesCont_column_hiddenCont_button"
								>
									<p>GET STARTED</p>
								</div>
							</div>
						</div>
					</div>
					{this.state.price > 0 && (
						<div className="messengerIntroSelect_cont_promocodeCont">
							<p className="messengerIntroSelect_cont_promocodeCont_title">Enter promo code or discount code.</p>
							<div
								className={`messengerIntroSelect_cont_promocodeCont_inputRow ${
									this.state.codeApplied == true ? 'messengerIntroSelect_cont_promocodeCont_inputRow-applied' : ''
								}`}
							>
								<div
									className={`messengerIntroSelect_cont_promocodeCont_inputRow_inputCont ${
										this.state.codeApplied == true
											? 'messengerIntroSelect_cont_promocodeCont_inputRow_inputCont-applied'
											: this.state.incorrectCode == true
											? 'messengerIntroSelect_cont_promocodeCont_inputRow_inputCont-error'
											: ''
									}`}
								>
									<input
										className="messengerIntroSelect_cont_promocodeCont_inputRow_inputCont_input"
										type="text"
										placeholder="Enter the code"
										value={this.state.code}
										maxlength="6"
										onChange={this.handleCodeInputChange}
									/>
									{this.state.codeApplied == true && (
										<span className="messengerIntroSelect_cont_promocodeCont_inputRow_inputCont_appliedText">
											Code applied
										</span>
									)}
									{this.state.incorrectCode == true && (
										<span className="messengerIntroSelect_cont_promocodeCont_inputRow_inputCont_incorrectText">
											Incorrect code
										</span>
									)}
								</div>
								{this.state.codeApplied == false && (
									<div
										className="button messengerIntroSelect_cont_promocodeCont_inputRow_button"
										onClick={() => this.applyPromoCode()}
									>
										APPLY
									</div>
								)}
								{this.state.codeApplied == true && (
									<img
										src={removeCodeIcon}
										className="messengerIntroSelect_cont_promocodeCont_inputRow_removeButton"
										onClick={this.removePromoCode}
									/>
								)}
							</div>
						</div>
					)}
					<div className="messengerIntroSelect_cont_noteCont">
						<div className="messengerIntroSelect_cont_noteCont_left">
							<img alt="quan" src={noteIcon} />
						</div>
						<div className="messengerIntroSelect_cont_noteCont_right">
							<h4>Please note</h4>
							<p>
								We don’t accept unsolicited material. QUAN artists are unable to accept questions seeking opinions or
								reviews of unsolicited material, such as your original music or song demos. Any requests like these will
								be declined by the artists.
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user.user,
	};
};

// const mapDispatchToProps = (dispatch) =>
// 	bindActionCreators(
// 		{
// 			fetchAllConversations,
// 		},
// 		dispatch,
// 	);

MessangerIntroSelectComponent.propTypes = {
	user: PropTypes.object,
};

export default MessangerIntroSelectComponent;
