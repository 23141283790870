// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--4+wmA";
export var chromeframe = "style-module--chromeframe--C2cUc";
export var container = "style-module--container--uxXEF";
export var container_bottomCont = "style-module--container_bottomCont--B3dIs";
export var container_delimiter = "style-module--container_delimiter--IfDEo";
export var container_topCont = "style-module--container_topCont--b5Nkd";
export var container_topCont_textCont = "style-module--container_topCont_textCont--KLliD";
export var container_topCont_videoCont = "style-module--container_topCont_videoCont--I7vQI";
export var errorText = "style-module--errorText--Gwa8+";
export var overlay = "style-module--overlay--6Zusk";
export var textDefault = "style-module--text-default--NJ4Ug";
export var textInput = "style-module--text-input--7SJ0c";
export var videoJs = "style-module--video-js--yMGq6";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--1EnJC";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--3DpMp";
export var vjsPoster = "style-module--vjs-poster--4+szQ";