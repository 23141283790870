import { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";

const NEW_CHAT_MESSAGE_EVENT = "newChatMessage"; // Name of the event
const SOCKET_SERVER_URL = process.env.API_DOMAIN;

const Socket = (conversationId) => {
  const [update, setUpdate] = useState([]); // Sent and received messages
  const socketRef = useRef();

  useEffect(() => {
    // Creates a WebSocket connection
    socketRef.current = socketIOClient(SOCKET_SERVER_URL, {
      query: { conversationId: conversationId },
      withCredentials: true,
    });

    socketRef.current.on("connect", () => {
      console.log("Socket connected");
    });

    // Listens for incoming messages
    socketRef.current.on(NEW_CHAT_MESSAGE_EVENT, (data) => {
      const incomingData = {
        ...data,
      };
      console.log("received message from socket room");
      setUpdate((update) => [...update, incomingData]);
    });

    if (socketRef.current) {
      socketRef.current.emit(NEW_CHAT_MESSAGE_EVENT, {
        body: "hello",
        senderId: socketRef.current.id,
      });
    }

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      socketRef.current.disconnect();
    };
  }, [conversationId]);

  return { update };
};

export default Socket;
