import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Message from '../Message/index.js';
import Socket from '../utils/socket.js';

import './MessageList.scss';
import ConversationDeclinedComponent from '../conversationDeclinedComponent/conversationDeclinedComponent.js';
import MessengerReviewComponent from '../messengerReviewComponent/messengerReviewComponent.js';
import { selectUser } from 'state/common/auth/selector';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMessages } from 'state/common/messages/thunk';
import { selectMessages } from 'state/common/messages/selector';
import { isEqual } from 'lodash/isEqual';
import { clearMessages } from 'state/common/messages/action';
import { clearConversation } from 'state/common/conversations/action';

export default function MessageList(props) {
	const dispatch = useDispatch();
	const messages = Object.values(useSelector((state) => state.messages, isEqual));
	const user = useSelector(selectUser);
	const { update } = Socket(props.conversation.id);
	const [showClosedRequestInfo, setShowClosedRequestInfo] = useState(true);

	useEffect(() => {
		console.log('Socket update');
		dispatch(fetchMessages(props.conversation.id));
		props.removeVideoUploadingPreviewElements();
		return () => {
			dispatch(clearMessages());
		};
	}, [update]);

	useEffect(() => {
		window.scrollTo(0, document.body.scrollHeight);
	}, [messages]);

	const renderMessages = () => {
		let i = 0;
		const messageCount = messages.length;
		const tempMessages = [];

		while (i < messageCount) {
			const previous = messages[i - 1];
			const current = messages[i];
			const next = messages[i + 1];
			const isMine = current.from === user.id;
			const currentMoment = moment.unix(current.created_at);
			let prevBySameAuthor = false;
			let nextBySameAuthor = false;
			let startsSequence = true;
			let endsSequence = true;
			let showTimestamp = true;

			if (previous) {
				const previousMoment = moment.unix(previous.created_at);
				const previousDuration = moment.duration(currentMoment.diff(previousMoment));
				prevBySameAuthor = previous.from === current.from;

				if (prevBySameAuthor && previousDuration.as('hours') < 1) {
					startsSequence = false;
				}

				if (previousDuration.as('hours') < 1) {
					showTimestamp = false;
				}
			}

			if (next) {
				const nextMoment = moment.unix(next.created_at);
				const nextDuration = moment.duration(nextMoment.diff(currentMoment));
				nextBySameAuthor = next.from === current.from;

				if (nextBySameAuthor && nextDuration.as('hours') < 1) {
					endsSequence = false;
				}
			}

			tempMessages.push(
				<Message
					key={i}
					isMine={isMine}
					startsSequence={startsSequence}
					endsSequence={endsSequence}
					showTimestamp={showTimestamp}
					data={current}
				/>,
			);

			// Proceed to the next message.
			i += 1;
		}

		return tempMessages;
	};

	return (
		<div className="message-list" style={{ width: props.conversation.closed ? '100vw' : '601px' }}>
			<div className="message-list-container">{renderMessages()}</div>
			{Boolean(props.conversation.closed) && !Boolean(props.conversation.declined) && (
				<>
					{showClosedRequestInfo && props.conversation.rating == null && (
						<div className="message-list_closedconversation">
							{props.conversation.auto_close_at !== null && (
								<p className="message-list_closedconversation_text">
									This request was automatically closed. Please leave a review.
								</p>
							)}
							{props.conversation.auto_close_at == null && (
								<p className="message-list_closedconversation_text">
									This request was closed by {props.conversation.artist.fullname}. Please leave a review.
								</p>
							)}
						</div>
					)}
					<div className="message-list_reviewWrapper">
						<MessengerReviewComponent
							conversationId={props.conversation.id}
							artist={props.conversation.artist}
							reviewObj={props.conversation.rating}
						/>
					</div>
				</>
			)}
			{Boolean(props.conversation.closed) && Boolean(props.conversation.declined) && (
				<ConversationDeclinedComponent
					declineReason={props.conversation.declinedReason}
					artist={props.conversation.artist}
					artistUrlTag={props.conversation.artistUrlTag}
				/>
			)}
		</div>
	);
}
