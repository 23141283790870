import React, { FC } from 'react';
import Layout from 'components/layout';
// eslint-disable-next-line import/no-unresolved
import MessangerComponent from '../old/messenger/messangerComponent.js';

type Props = {
	path: string;
};

const MessengerPage: FC<Props> = () => {
	return (
		<Layout>
			<MessangerComponent />
		</Layout>
	);
};

export default MessengerPage;
