import React, { FC, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'gatsby';
import VideoPlayer from 'components/molecules/VideoPlayer/VideoPlayer.js';
import { useSelector } from 'react-redux';
import { selectUser } from 'state/common/auth/selector';
import questionLogo from 'assets/images/logoYellow.svg';
import { TweenMax as TM, ScrollToPlugin, Power2, Power3 } from 'gsap/all';
import gsap from 'gsap';
import * as styles from './style.module.scss';
import { Button } from '../../atoms/Button';
import { WithWindowResize } from '../../../HOCS/withWindowResize';

gsap.registerPlugin(ScrollToPlugin);

export const UserWelcomeComponent: FC = WithWindowResize(({ isMobile, band }) => {
	const user = useSelector(selectUser);

	const handleClick = () => {
		gsap.to(window, { duration: 1, scrollTo: '#artistsSection' });
	};
	return (
		<div className={styles.container}>
			<div className={styles.container_topCont}>
				<div className={styles.container_topCont_textCont}>
					<h2>
						Welcome {user.fullname.substring(0, user.fullname.indexOf(' '))}, <br /> ask your first question
					</h2>
					<span>Be mentored directly by the people who inspire you the most.</span>
				</div>
				<div className={styles.container_topCont_videoCont}>
					<VideoPlayer
						videoUrl="https://quan-dev-processed.s3.amazonaws.com/static/homepage_video.m3u8"
						showBigFormatPreview
						controlsAtPreview
						wideAngle
						videoIsProcessed={false}
						fullscreen={false}
					/>
				</div>
			</div>
			<div className={styles.container_delimiter} />
			<div className={styles.container_bottomCont}>
				<img src={questionLogo} alt="logo" />
				<Button type="button" className="ghost" onClick={handleClick}>
					See who is available
				</Button>
			</div>
		</div>
	);
});
