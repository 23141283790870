import React from 'react';
import { Link } from 'gatsby';
import { Header } from 'components/molecules/Header';
import { LOGIN_SCREEN } from 'router/routes';
import image from 'assets/images/check.gif';
import * as styles from './style.module.scss';

type Props = { path: string };

export const CheckYourEmail: React.FC<Props> = () => {
	return (
		<div className={styles.container} data-testid="test">
			<Header />
			<div className={styles.container_content}>
				<div className={styles.bg}>
					<img src={image} alt="check" />
				</div>
				<h1 className={styles.container_content_title}> Your account has been created</h1>
				<h3 className={styles.container_content_subtitle}>
					To compete your profile and start asking on QUAN, please confirm your email address.
				</h3>
				<div>
					<Link to={LOGIN_SCREEN}>
						<button type="button" className={styles.redirect}>
							Back to log in
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
};
