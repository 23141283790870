import React, { useState } from 'react';
import { LoginForm } from 'components/molecules/LoginForm';
import { SignupForm } from 'components/molecules/SignupForm';
import { Link, navigate } from 'gatsby';
import { HOME_SCREEN } from 'router/routes';
import * as styles from './style.module.scss';
import { useAuth } from '../../../utils/hooks';
import Seo from '../../seo';
import { WithWindowResize } from '../../../HOCS/withWindowResize';
import { AuthHeaderMobile } from '../../atoms/AuthHeaderMobile';
import { Logo } from '../../atoms/Logo';

type Props = {
	path: string;
};

const forms: {
	login: React.ReactNode;
	signup: React.ReactNode;
} = {
	login: <LoginForm />,
	signup: <SignupForm />,
};

const reverseCond = (tab: string) => (tab === 'login' ? 'signup' : 'login');

export const Login: React.FC<Props> = WithWindowResize(({ isMobile }) => {
	const isLoggedInUser = useAuth();

	const [tab, setTab] = useState<'login' | 'signup'>('login');

	if (isLoggedInUser) {
		navigate(HOME_SCREEN);
		return null;
	}
	const handleTab = () => {
		setTab((prevState) => reverseCond(prevState));
	};

	return (
		<>
			<Seo title="Login" />
			<div data-testid="test" className={styles.page}>
				{isMobile ? (
					<AuthHeaderMobile className={styles.mobHeader}>
						<button onClick={handleTab} className={styles.link}>
							{reverseCond(tab)}
						</button>
					</AuthHeaderMobile>
				) : (
					<div className={styles.container_logo}>
						<Link to={HOME_SCREEN}>
							<Logo />
						</Link>
					</div>
				)}
				<div className={styles.container}>
					{!isMobile ? (
						<>
							{forms.login}
							{forms.signup}
						</>
					) : (
						forms[tab]
					)}
				</div>
			</div>
		</>
	);
});
