import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchAllConversations } from 'state/common/conversations/thunk';
import { clearConversation } from 'state/common/conversations/action';

import { TweenMax as TM, ScrollToPlugin, Power2, Power3 } from 'gsap/all';
import { isBrowser, isMobile, isDesktop } from 'react-device-detect';

import { InView } from 'react-intersection-observer';
import lottie from 'lottie-web';

import './Dashboard.scss';

import CoachmarkComponent from '../../../old/components/coachmarkComponent/coachmarkComponent.js'; //
import ConversationListItem from '../../../old/components/conversationListItem/conversationListItem.js'; //
import UploadingConversationListItem from '../../../old/components/uploadingConversationListItem/uploadingConversationListItem.js'; //
import { FooterSm } from '../../molecules/FooterSm';

import animationData1 from '../../../assets/lotties/loaders/1.json';
import animationData1Mobile from '../../../assets/lotties/loaders/1_respo.json';
import animationData2Mobile from '../../../assets/lotties/loaders/respo_dashboard_filled.json';
import { Header } from 'components/molecules/Header';
import { setPageLoader } from 'state/common/pageLoader/thunk';
import { DashboardEmptyStateBlock } from '../DashboardEmptyStateBlock/DashboardEmptyStateBlock';

let animObj1 = null;

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: 'transparent',
			activeSection: 0,
			showForm: 'none',
			loadingConversationObj: null,
			showHeroSection: true,
			playedOnce: false,
			showNotifMsg: false,
			loading: true,
			firstTime: false,
			errorMsg: 'Server error occoured.',
			artists: [],
			conversations: [],
			openedQuestions: [],
			archivedQuestions: [],
			declinedQuestions: [],
			artistsElements: [],
			uploadingConversations: [],
		};
	}

	componentDidMount() {
		this.props.setPageLoader(true, 'dashboard');
		document.querySelector('html').classList.add('stop-scrolling-x');
		if (this.state.firstTime) {
			animObj1 = lottie.loadAnimation({
				container: this.animBox1, // the dom element that will contain the animation
				renderer: 'svg',
				loop: true,
				autoplay: true,
				animationData: isBrowser
					? animationData1
					: isMobile &&
					  (this.state.archivedQuestions.length > 0 ||
							this.state.openedQuestions.length > 0 ||
							this.state.uploadingConversations.length > 0 ||
							this.state.declinedQuestions.length > 0)
					? animationData2Mobile
					: animationData1Mobile, // the path to the animation json
			});
			this.props.fetchAllConversations();
			this.setState({ firstTime: false });
		} else {
			this.props.fetchAllConversations();
		}
		this.props.clearConversation();
		this.handleWindowSizeChange();
		console.log('end of component did mount');
	}

	componentDidUpdate() {
		document.querySelector('html').classList.add('stop-scrolling-x');
	}

	componentWillReceiveProps(nextProps) {
		// Any time props.email changes, update state.
		if (nextProps.finishedConversationUpload !== this.props.finishedConversationUpload) {
			if (nextProps.finishedConversationUpload) {
				setTimeout(() => {
					this.conversationUploadFinished(nextProps.finishedConversationUpload);
					this.context.conversationUploadFinished(false);
				}, 100);
			}
		}
		if (nextProps.conversationObj !== this.props.conversationObj) {
			//this.addNewDraft(nextProps.draftMessageObj);
			this.setState({ loadingConversationObj: nextProps.conversationObj });
		}
		if (nextProps.conversations) {
			this.sortConversations(nextProps.conversations);
		}
	}

	addNewConversation = (conversationObj) => {
		var uploadConversationsArray = [];
		uploadConversationsArray.push({
			artist: conversationObj.artist,
		});
		return uploadConversationsArray;
	};

	conversationUploadFinished = (val) => {
		if (val) {
			this.setState({ loadingConversationObj: null });
			this.getAllConversations();
		} else {
			this.setState({
				showNotifMsg: true,
				showRefreshSpinner: false,
				errorMsg: 'Failed to create conversation with artist.',
			});
		}
	};

	componentWillUnmount() {
		document.querySelector('html').classList.remove('stop-scrolling-x');
		if (typeof window !== 'undefined') {
			window.removeEventListener('scroll', this.handleScroll);
		}
		if (this.scroll) {
			this.scroll.destroy();
		}
	}

	scrollAnimation() {
		if (typeof window !== 'undefined') {
			window.dispatchEvent(new Event('resize'));
			window.scroll = this.scroll;
		}
		if (isBrowser) {
			this.scroll?.on('scroll', this.handleScroll);
		}
		setTimeout(() => {
			this.scroll?.update();
		}, 500);
	}

	handleScroll = (event) => {
		var headerTitle = document.getElementById('headerTitle');
		if (
			this.state.archivedQuestions.length === 0 &&
			this.state.openedQuestions.length === 0 &&
			this.state.uploadingConversations.length === 0 &&
			this.state.declinedQuestions.length === 0
		) {
			if (event.scroll.y > 50) {
				this.headerDivider.style.transition = 'all 0.2s';
				this.headerDivider.style.opacity = '0';
			} else {
				this.headerDivider.style.transition = 'all 0.2s';
				this.headerDivider.style.opacity = '1';
			}
			if (event.scroll.y > 140) {
				if (this.h1 && headerTitle !== null) {
					this.h1.style.transition = 'all 0.2s';
					this.h1.style.opacity = '0';
					headerTitle.style.transition = 'all 0.2s';
					headerTitle.style.display = 'block';
				}
			} else {
				if (this.h1 && headerTitle !== null) {
					this.h1.style.transition = 'all 0.2s';
					this.h1.style.opacity = '1';
					headerTitle.style.transition = 'all 0.2s';
					headerTitle.style.display = 'none';
				}
			}
		}
	};

	handleWindowSizeChange = () => {
		if (typeof window !== 'undefined') {
			this.setState({ width: window.innerWidth });
		}
	};

	buttonClick = () => {
		this.props.callBackForFormShow('flex');
	};

	callBackForActiveSection = (newVal) => {
		this.setState({ activeSection: newVal });
	};
	callBackForFormShow = (show) => {
		this.setState({ showForm: show });
	};

	sortConversations = (conversations) => {
		var openedQuestions = [];
		var archivedQuestions = [];
		var declinedQuestions = [];
		conversations?.forEach(function (conversation) {
			if (
				conversation.closed === 0 ||
				(conversation.closed === 1 && conversation.answered === 1 && conversation.rating == null)
			) {
				openedQuestions.push(conversation);
			} else {
				if (conversation.declined === 0) {
					archivedQuestions.push(conversation);
				} else {
					declinedQuestions.push(conversation);
				}
			}
		});
		var uploadConversationArray = [];
		if (this.props.conversationObj !== null) {
			//uploadConversationArray = this.addNewConversation(this.props.conversationObj);
		}
		this.setState({
			openedQuestions: openedQuestions,
			archivedQuestions: archivedQuestions,
			uploadingConversations: uploadConversationArray,
			declinedQuestions: declinedQuestions,
		});
		setTimeout(() => {
			if (
				openedQuestions.length === 0 &&
				archivedQuestions.length === 0 &&
				uploadConversationArray.length === 0 &&
				declinedQuestions.length === 0
			) {
				this.scrollAnimation();
			}
		}, 100);
		this.props.setPageLoader(false);
	};

	handleContentVisibility(inView) {
		if (inView && !this.state.playedOnce) {
			TM.fromTo(
				this.h1,
				1,
				{ transform: 'translateY(20%)', opacity: 0 },
				{
					transform: 'translateY(0%)',
					opacity: 1,
					ease: Power3.easeOut,
					delay: 0.2,
				},
			);
			this.setState({ playedOnce: true });
		}
	}

	setShowMessage = (val) => {
		this.setState({ showNotifMsg: val });
	};

	render() {
		const openedQuestions = this.state.openedQuestions.map((conversation, index) => {
			return (
				<ConversationListItem
					key={conversation.id + conversation.created_at}
					conversation={conversation}
					opened={true}
				/>
			);
		});
		const archivedQuestions = this.state.archivedQuestions.map((conversation, index) => {
			return (
				<ConversationListItem
					key={conversation.id + conversation.created_at}
					conversation={conversation}
					opened={false}
				/>
			);
		});
		const declinedQuestions = this.state.declinedQuestions.map((conversation, index) => {
			return (
				<ConversationListItem
					key={conversation.id + conversation.created_at}
					conversation={conversation}
					opened={false}
				/>
			);
		});
		const uploadingConversations = this.state.uploadingConversations.map((conversation, index) => {
			return (
				<UploadingConversationListItem
					key={conversation.id + conversation.created_at}
					conversation={conversation}
					opened={true}
				/>
			);
		});
		return (
			<div id="dashboard" data-scroll-container className="dashboard">
				{this.state.archivedQuestions.length === 0 &&
					this.state.openedQuestions.length === 0 &&
					this.state.uploadingConversations.length === 0 &&
					this.state.declinedQuestions.length === 0 && <DashboardEmptyStateBlock />}
				{(this.state.archivedQuestions.length != 0 ||
					this.state.openedQuestions.length != 0 ||
					this.state.uploadingConversations.length != 0 ||
					this.state.declinedQuestions.length != 0) && (
					<>
						<InView as="div" threshold={0.1} onChange={(inView) => this.handleContentVisibility(inView)}>
							<div className="dashboard__cont" style={{ paddingBottom: isDesktop ? '200px' : '100px' }}>
								{(this.state.openedQuestions.length > 0 || this.state.uploadingConversations.length > 0) && (
									<div className="dashboard__cont_openedQuestions">
										<div className="dashboard__cont_openedQuestions_header">
											<p>Open questions</p>
										</div>
										<div className="dashboard__cont_openedQuestions_coachmark">
											<CoachmarkComponent
												text="Sit tight! The artist gets back to you soon."
												position="top"
												field="2"
												showMessage={true}
											/>
										</div>
										<div>{uploadingConversations}</div>
										<div>{openedQuestions}</div>
									</div>
								)}
								{this.state.archivedQuestions.length > 0 && (
									<div className="dashboard__cont_archivedQuestions">
										<div className="dashboard__cont_archivedQuestions_header">
											<p>Closed</p>
										</div>
										<div>{archivedQuestions}</div>
									</div>
								)}
								{this.state.declinedQuestions.length > 0 && (
									<div className="dashboard__cont_archivedQuestions">
										<div className="dashboard__cont_archivedQuestions_header">
											<p>Declined</p>
										</div>
										<div>{declinedQuestions}</div>
									</div>
								)}
							</div>
						</InView>
						<FooterSm />
					</>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		// loading: state.status.loading,
		// conversations: state.conversations.conversations,
		conversations: state.conversations.conversations,
		user: state.user,
	};
};

Dashboard.propTypes = {
	fetchAllConversations: PropTypes.func,
	conversations: PropTypes.array,
	user: PropTypes.object,
};

export default connect(mapStateToProps, { fetchAllConversations, setPageLoader, clearConversation })(Dashboard);
