import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'components/atoms/Button';
import { userResetPassword } from 'state/common/auth/thunk';
import { emailValidation, initValidation, ValidResponse } from '../../../utils/validation';
import * as styles from './style.module.scss';
import { AuthField } from '../../atoms/AuthField';

const initForm = {
	email: '',
};
export const ResetPassForm: React.FC = () => {
	const [form, setForm] = useState(initForm);
	const [{ errorField }, setValidation] = useState<ValidResponse>(initValidation);
	const dispatch = useDispatch();

	const onFieldChange = (name: string, value: string) => {
		if (name === 'email') {
			setForm((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		const { email } = form;
		const validation = emailValidation(email);

		if (validation.success) {
			dispatch(
				userResetPassword({
					email,
				}),
			);
			setForm(initForm);
			setValidation(initValidation);
		} else {
			setValidation({
				success: false,
				errorField: { email: validation.errorText as string },
			});
		}
	};

	return (
		<form className={styles.form} data-testid="test" onSubmit={handleSubmit}>
			<h1 className={styles.form_title}>Reset Password?</h1>
			<p className={styles.form_subtitle}>
				Enter the email associated with your account and we'll send an email with instructions to reset your password.
			</p>
			<div className={styles.fieldControl}>
				<AuthField
					className={styles.authField}
					type="email"
					name="email"
					value={form.email}
					onChange={onFieldChange}
					error={errorField.email}
					placeholder="E-mail"
				/>
			</div>
			<div className={styles.submitBlock}>
				<Button className="signupFormBtn" type="submit">
					Reset password
				</Button>
			</div>
		</form>
	);
};
