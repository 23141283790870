// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--gHR26";
export var chromeframe = "style-module--chromeframe--X915l";
export var container = "style-module--container--WWVkj";
export var container_logo = "style-module--container_logo--RlTc+";
export var errorText = "style-module--errorText--lHuvC";
export var link = "style-module--link--puKgG";
export var mobHeader = "style-module--mobHeader--tHF+K";
export var overlay = "style-module--overlay--QeU7w";
export var page = "style-module--page--Ghcmh";
export var textDefault = "style-module--text-default--a11i2";
export var textInput = "style-module--text-input--QzP-h";
export var videoJs = "style-module--video-js--JBKhc";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--k3J2x";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--SM618";
export var vjsPoster = "style-module--vjs-poster--ZCIB8";