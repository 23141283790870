import React, { FC } from 'react';
import Layout from 'components/layout';
import Dashboard from '../components/organisms/Dashboard/Dashboard.js';
import { Header } from 'components/molecules/Header';

type Props = {
	path: string;
};

const DashboardPage: FC<Props> = () => {
	return (
		<Layout>
			<Header />
			<Dashboard />
		</Layout>
	);
};

export default DashboardPage;
