import React, { useState, useEffect } from 'react';
import './uploadingConversationListItem.scss';
import { getArtist } from '../../utils/api';
import Avatar from 'react-avatar';
import { BrowserView, MobileView, isBrowser, TabletView, isMobile, isTablet, isIPad13 } from 'react-device-detect';

export default function UploadingConversationListItem(props) {
	const [isInitialMount, setInitialMount] = useState(true);
	const { conversation, opened } = props;
	const [artist, setArtist] = useState([]);
	const [firstMessage, setFirstMessage] = useState([]);

	function getArtistData() {
		var data = {
			id: conversation.artist,
		};
		getArtist(data)
			.then((result) => {
				setArtist({
					id: result.id,
					fullname: result.fullname,
					link_tag: result.link_tag,
					name: result.firstname,
					price_low: result.price_low,
					price_high: result.price_high,
					price_mid: result.price_mid,
					avatarPic: returnCDNMediaLink(result.picture),
				});
			})
			.catch((e) => {
				console.log('failed load artist data: ' + e);
			});
	}

	function returnCDNMediaLink(url) {
		var url = new URL(url);
		url.hostname = 'd121ftohl5zq6b.cloudfront.net';
		return url.toString();
	}

	useEffect(() => {
		if (isInitialMount) {
			getArtistData();
			setInitialMount(false);
		}
		return;
	}, []);

	return (
		<div
			className={'uploadingConversationListItem uploadingConversationListItem_greyscale'}
			style={{
				height: isMobile ? '132px' : '86px',
			}}
		>
			<div
				className="uploadingConversationListItem_cont"
				style={{
					flexDirection: isMobile ? 'column' : '',
					background: '#121212',
				}}
			>
				<div className={'uploadingConversationListItem_cont_left'}>
					<Avatar
						className="uploadingConversationListItem_cont_left_avatar"
						size="36"
						color={
							['#87D1AA', '#6277CB', '#FFC996', '#FCE2AE', '#FF7A86'][artist.fullname ? artist.fullname.length % 5 : 2]
						}
						name={artist.fullname}
						maxInitials={2}
						textSizeRatio={3.7}
						round={true}
						src={artist.avatarPic}
					/>
					<p className="uploadingConversationListItem_cont_left_text">{artist.fullname}</p>
				</div>
				<div
					className="uploadingConversationListItem_cont_right"
					style={{
						marginLeft: isMobile ? '50px' : '',
						marginBottom: isMobile ? '0px' : '',
					}}
				>
					<p className="uploadingConversationListItem_cont_right_daysLeft">Saving...</p>
				</div>
			</div>
		</div>
	);
}
