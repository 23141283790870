import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { LOGIN_SCREEN, SIGNUP_SCREEN } from './routes';
import { useAuth } from '../utils/hooks';

const PrivateRoute = ({ component: Component, location, ...rest }: any) => {
	const loggedIn = useAuth();
	if (loggedIn === false && (location.pathname !== LOGIN_SCREEN || location.pathname !== SIGNUP_SCREEN)) {
		navigate(LOGIN_SCREEN);
		return null;
	}

	return <Component {...rest} />;
};
export default PrivateRoute;
