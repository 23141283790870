import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import { FieldNameType } from 'types/textField';
import { userRecoverPassword } from 'state/common/auth/thunk';
import { AuthFormFieldType } from 'types/auth';
import { AuthField } from 'components/atoms/AuthField';
import { Button } from 'components/atoms/Button';
import { recoverPassFormTemplate } from 'config/auth';
import { initValidation, passwordValidation, ValidResponse } from '../../../utils/validation';
import * as styles from './style.module.scss';

const initForm = {
	password: '',
	confirm: '',
};

export const RecoverPassForm: React.FC = () => {
	const [form, setForm] = useState(initForm);
	const [{ errorField }, setValidation] = useState<ValidResponse>(initValidation);
	const dispatch = useDispatch();

	const [email] = useQueryParam('email', StringParam);
	const [token] = useQueryParam('token', StringParam);

	const onFieldChange = (name: FieldNameType, value: string) => {
		if (name === 'password' || name === 'confirm') {
			setForm((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		const { password, confirm } = form;
		const validation = passwordValidation(password, confirm);

		if (validation.success) {
			dispatch(
				userRecoverPassword({
					email: email ?? '',
					password,
					token: token ?? '',
				}),
			);
			setForm(initForm);
			setValidation(initValidation);
		} else {
			setValidation(validation);
		}
	};

	const fields = useMemo(
		() =>
			Object.values(recoverPassFormTemplate).map((fieldProps: AuthFormFieldType) => {
				return (
					<AuthField
						className={styles.authField}
						{...fieldProps}
						onChange={onFieldChange}
						key={`recovery_${fieldProps.name}`}
						error={errorField[fieldProps.name as keyof typeof errorField]}
						value={form[fieldProps.name as keyof typeof form]}
					/>
				);
			}),
		[form, errorField],
	);

	return (
		<form className={styles.form} data-testid="test" onSubmit={handleSubmit}>
			<h1 className={styles.form_title}>Create new password</h1>
			<p className={styles.form_subtitle}>Your new password must be different from the previous password.</p>
			<p className={styles.form_subtitle}>Your new password must be 6+ characters.</p>
			<div className={styles.fieldControl}>{fields}</div>
			<div className={styles.submitBlock}>
				<Button className="signupFormBtn" type="submit">
					Submit password
				</Button>
			</div>
		</form>
	);
};
