import React, { useMemo, useState } from 'react';
import { AuthField } from 'components/atoms/AuthField';
import { Button } from 'components/atoms/Button';
import { signupFormTemplate } from 'config/auth';
import { AuthFormFieldType } from 'types/auth';
import { userRegister } from 'state/common/auth/thunk';
import { useDispatch } from 'react-redux';
import * as styles from './style.module.scss';
import { authValidation, initValidation, ValidResponse } from '../../../utils/validation';

type Props = {
	title?: string;
	subtitle?: string;
	titleVariant?: string;
	subtitleVariant?: string;
};

const initForm = {
	email: '',
	password: '',
	fullname: '',
};

const [titleDefault, subtitleDefault] = ['Sign up', 'Not a member? Sign up.'];

export const SignupForm: React.FC<Props> = ({
	title = titleDefault,
	subtitle = subtitleDefault,
	titleVariant = '',
	subtitleVariant = '',
}) => {
	const [form, setForm] = useState(initForm);
	const [{ errorField }, setValidation] = useState<ValidResponse>(initValidation);
	const dispatch = useDispatch();

	const onFieldChange = (name: string, value: string) => {
		if (name === 'email' || name === 'password' || name === 'fullname') {
			setForm((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		setValidation(initValidation);
		e.preventDefault();
		const { fullname, email, password } = form;
		const validation = authValidation(email, password, fullname);
		if (validation.success) {
			dispatch(userRegister(form));
			setValidation(initValidation);
		} else {
			setValidation(validation);
		}
	};

	const fields = useMemo(
		() =>
			Object.values(signupFormTemplate).map((fieldProps: AuthFormFieldType, index) => {
				return (
					<AuthField
						className={styles.authField}
						{...fieldProps}
						onChange={onFieldChange}
						key={`signup_${fieldProps.name || index}`}
						error={errorField[fieldProps.name as keyof typeof errorField]}
						value={form[fieldProps.name as keyof typeof form]}
					/>
				);
			}),
		[form, errorField],
	);

	return (
		<form className={styles.form} data-testid="test" onSubmit={handleSubmit}>
			<h1 className={styles[titleVariant] || styles.form_title}>{title}</h1>
			<p className={styles[subtitleVariant] || styles.form_subtitle}>{subtitle}</p>
			<div className={styles.fieldControl}>{fields}</div>
			<div className={styles.submitBlock}>
				<Button className="signupFormBtn" type="submit">
					Create account
				</Button>
			</div>
		</form>
	);
};
