import React, { useState } from 'react';
import { TextField } from '../TextField';
import eyeIcon from '../../../assets/icons/eye.svg';
import eyeIconOff from '../../../assets/icons/eye-off.svg';
import { TextFieldProps } from '../../../types/textField';
import * as styles from './style.module.scss';

export const AuthField: React.FC<TextFieldProps> = ({ type, placeholder, error, ...rest }) => {
	const [visible, setVisible] = useState(false);
	const [activeLabel, setActiveLabel] = useState(false);

	const handleVisible = () => {
		setVisible((prevState) => !prevState);
	};

	const handleFocus = (focus: boolean) => {
		setActiveLabel(focus);
	};

	const activeClass = activeLabel ? styles.activeField : '';
	const errorClass = error ? styles.errorField : '';
	const isPasswordField = type === 'password';
	return (
		<label className={`${styles.fieldWrapper} ${activeClass} ${errorClass}`}>
			{error && <p className={styles.errorMessage}>{error}</p>}
			<TextField type={visible ? 'text' : type} {...rest} onFocus={handleFocus} />
			<span>{placeholder}</span>
			{isPasswordField && (
				<img
					className={styles.fieldIcon}
					src={visible ? eyeIconOff : eyeIcon}
					alt="field icon"
					onClick={handleVisible}
					aria-hidden
				/>
			)}
		</label>
	);
};
