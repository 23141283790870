// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--+8LE7";
export var authField = "style-module--authField--VP9y5";
export var chromeframe = "style-module--chromeframe--h4vTe";
export var errorText = "style-module--errorText--a70EB";
export var fieldControl = "style-module--fieldControl--KViKd";
export var form = "style-module--form--1a-8y";
export var form_subtitle = "style-module--form_subtitle--8g54h";
export var form_title = "style-module--form_title--KLYDo";
export var overlay = "style-module--overlay--jvAUP";
export var submitBlock = "style-module--submitBlock--uAyHL";
export var subtitleDefault = "style-module--subtitleDefault--XsCaZ";
export var textDefault = "style-module--text-default--N+H6+";
export var textInput = "style-module--text-input--fV2lf";
export var titleMedium = "style-module--titleMedium--qN0yU";
export var videoJs = "style-module--video-js--NlvJu";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--B5y63";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--DUJN0";
export var vjsPoster = "style-module--vjs-poster--hBbVV";