// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--HC1D9";
export var authField = "style-module--authField--FloNe";
export var chromeframe = "style-module--chromeframe--p+Fqy";
export var errorText = "style-module--errorText--4HEI1";
export var fieldControl = "style-module--fieldControl--x-BLt";
export var forgotLink = "style-module--forgotLink--dZYNr";
export var forgotPass = "style-module--forgotPass--zXlSK";
export var form = "style-module--form--CWXLE";
export var form_subtitle = "style-module--form_subtitle--5tCpe";
export var form_title = "style-module--form_title--JjR+O";
export var overlay = "style-module--overlay--QzRMB";
export var submitBlock = "style-module--submitBlock--HsPfz";
export var textDefault = "style-module--text-default--wff5U";
export var textInput = "style-module--text-input--FJFPj";
export var videoJs = "style-module--video-js--2SHIZ";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--Eddoi";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--oheAs";
export var vjsPoster = "style-module--vjs-poster--RiAGt";