import React, { FC } from 'react';
import Layout from 'components/layout';
// eslint-disable-next-line import/no-unresolved
import ProfileComponent from '../old/components/profile/profileComponent.js';
import { Header } from '../components/molecules/Header';

type Props = {
	path: string;
};

const ProfilePage: FC<Props> = () => {
	return (
		<Layout>
			<Header />
			<ProfileComponent />
		</Layout>
	);
};

export default ProfilePage;
