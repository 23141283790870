// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--K0kfo";
export var authField = "style-module--authField--GJduu";
export var chromeframe = "style-module--chromeframe--NBz7U";
export var errorText = "style-module--errorText--eBpzg";
export var fieldControl = "style-module--fieldControl--YUiWK";
export var form = "style-module--form--ihr-r";
export var form_subtitle = "style-module--form_subtitle--gYwV6";
export var form_title = "style-module--form_title--Q3FrO";
export var overlay = "style-module--overlay--w8+p2";
export var submitBlock = "style-module--submitBlock--z7Vxt";
export var subtitleDefault = "style-module--subtitleDefault--hswwS";
export var subtitleXs = "style-module--subtitleXs--9A-yy";
export var textDefault = "style-module--text-default--dc-Qy";
export var textInput = "style-module--text-input--GH6fY";
export var titleMedium = "style-module--titleMedium--Swkqh";
export var videoJs = "style-module--video-js--IFt8z";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--7FzAm";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--Byupp";
export var vjsPoster = "style-module--vjs-poster--1iZ-B";