export const validateEmail = (email: string) => {
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
};

export const validatePassword = (pass: string) => {
	return pass.length > 6 && pass.length < 16;
};

export const validateFullName = (fullname: string) => {
	return fullname.trim().length > 6;
};

export type ValidResponse = {
	success: boolean;
	errorField: {
		fullname?: string;
		email?: string;
		password?: string;
		confirm?: string;
	};
};

export const initValidation = { success: false, errorField: {} };

export const authValidation = (email: string, password: string, fullname?: string) => {
	let validResp: ValidResponse = { success: true, errorField: {} };

	if (fullname !== undefined && !validateFullName(fullname)) {
		validResp = {
			success: false,
			errorField: {
				fullname: 'Enter correct full name',
			},
		};
	}
	if (!validateEmail(email)) {
		validResp = {
			success: false,
			errorField: {
				...validResp.errorField,
				email: 'Enter correct email',
			},
		};
	}
	if (!validatePassword(password)) {
		validResp = {
			success: false,
			errorField: {
				...validResp.errorField,
				password: 'Enter correct password',
			},
		};
	}
	return validResp;
};

export const emailValidation = (email: string) => {
	const success = validateEmail(email);

	return {
		success,
		errorText: !success && 'Enter valid email',
	};
};

export const passwordValidation = (password: string, confirm: string) => {
	let validResp: ValidResponse = { success: true, errorField: {} };

	if (!validatePassword(password)) {
		validResp = {
			success: false,
			errorField: {
				password: 'Enter correct password',
			},
		};
	}

	if (!(password === confirm)) {
		validResp = {
			success: false,
			errorField: {
				...validResp.errorField,
				confirm: 'Both passwords must match',
			},
		};
	}

	return validResp;
};
