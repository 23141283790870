// extracted by mini-css-extract-plugin
export var adaptiveText = "style-module--adaptiveText--Xw9AV";
export var bg = "style-module--bg--IyRTp";
export var chromeframe = "style-module--chromeframe--WcT5r";
export var container = "style-module--container--PF3F4";
export var container_content = "style-module--container_content--UIU3q";
export var container_content_subtitle = "style-module--container_content_subtitle--fD1pf";
export var container_content_title = "style-module--container_content_title--CTw-v";
export var errorText = "style-module--errorText--Ym0lS";
export var overlay = "style-module--overlay--jTYc1";
export var redirect = "style-module--redirect--5ROiU";
export var textDefault = "style-module--text-default--eu98j";
export var textInput = "style-module--text-input--qOjYQ";
export var videoJs = "style-module--video-js--j12Af";
export var vjsBigPlayButton = "style-module--vjs-big-play-button--wvc1Z";
export var vjsIconPlaceholder = "style-module--vjs-icon-placeholder--ug5gs";
export var vjsPoster = "style-module--vjs-poster--8nbU5";