import { AppDispatch } from 'state/createStore';
import { showPageLoader } from './action';

export const setPageLoader = (show: boolean, type: string | 'dashboard') => async (dispatch: AppDispatch) => {
	if (show !== undefined) {
		const data = {
			show,
			type,
		};
		dispatch(showPageLoader(data));
	}
};
